.leftsidecontent {
    margin-top: 42px !important;
    height: 100vh;
    overflow: auto;
  }
  
  .leftsidecontent .topcard {
    
    background-color: #000 !important;
    transition: all 0.3s ease-in-out;
    border: 2px solid #9e3467 !important;
  }
  
  .leftsidecontent .topcard:hover {
    box-shadow: inset 0px -1px 12px 3px #fd740d !important;
  }
  
  .leftsidecontent .topcard .card-heading {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
  
  .leftsidecontent .topcard .heading {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    text-align: center;
  }
  
  .leftsidecontent .topcard .Status {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    text-align: center;
  }
  
  .leftsidecontent .topcard .Amount {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    text-align: center;
  }
  
  .leftsidecontent .topcard .totoinvestment {
    width: 150px;
    height: 150px;
    margin: auto;
  }
  
  .leftsidecontent .topcard.profilcard .card_profileimg {
    width: 60px;
    height: 60px;
    margin: auto;
    border-radius: 50%;
    box-shadow: 0 0 0px 6px #9e3467;
  }
  
  .leftsidecontent .topcard.profilcard .userid {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-align: center;
  }
  
  .leftsidecontent .topcard.profilcard .number {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-align: center;
  }
  
  .leftsidecontent .topcard.profilcard .wallet {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-align: center;
  }
  
  .leftsidecontent .topcard.profilcard .copytext {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    border: 1px solid #9e3467;
    border-radius: 5px;
    padding: 6px;
    margin-top: 10px;
  }
  
  .leftsidecontent .samllcardsection .card {
   
    background-color: #000;
    border: 1px solid #9e3467;
    transition: all 0.3s ease-in-out;
  }
  
  .leftsidecontent .samllcardsection .card:hover {
    box-shadow: inset 0px -1px 12px 3px #9e3467 !important;
  }
  
  .leftsidecontent .samllcardsection .card .card-body .cardimage {
    width: 50px;
    height: 50px;
  }
  
  .leftsidecontent .samllcardsection .card .card-body .cardimage img {
    filter: invert(1);
  }
  
  .leftsidecontent .samllcardsection .card .card-body .card_heading {
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
    /* white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden */
  }
  
  .leftsidecontent .samllcardsection .card .card-body .sub_heading {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    text-align: end;
  }
  
  .leftsidecontent .progressbarSection .imageprogress {
    width: 50px;
    height: 50px;
  }