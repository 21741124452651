.backgroundimg {
    background: url(../../Media/login_bg4.jpg);
    background-size: auto;
    height: -webkit-fill-available;
    overflow: hidden;
    position: absolute;
    width: 100vw;
    z-index: 999;
}

@media (min-width: 320px) and (max-width: 991px) {
    .backgroundimg {
        background: url(../../Media/login_bg4.jpg);
        background-size: auto;
        height: 100vh;
        overflow: hidden;
        position: unset;
        width: 100vw;
        z-index: 999;
    }
    .MuiStack-root{
        font-size: 14px;
    }

    .login-modal-container {
        position: absolute;
        width: 95% !important;
        height: -moz-fit-content;
        height: fit-content;
        background-color: black;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999999;
        border: none;
    }
    .modal-w-btn {
        background-color: white;
        height: 35px;
        width: 95% !important;
        border: none;
        border-radius: 5px;
        color: black;
        font-size: 12px;
        font-weight: 600;
    }

    .Info-modal-container {
        position: absolute;
        width: 95% !important;
        height: 350px;
        background-color: black;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999999;
        border: none;}
  }
  
.img-div {
    height: 80px;
    width: 80px;

}


.login-card {
  height: 410px;
  width: 400px;
  background-color: rgb(17, 16, 16);
  position: absolute;
  z-index: 99999;
  top: 15%;
}

.login-card form {
    color: white;
}

.login-input {
    outline: none !important;
    border: none !important;
    background-color: rgb(29, 25, 25);
    height: 35px;
    width: 100%;
    border-radius: 5px;
    color: white !important;

}

.login-btn {
    width: 100%;
    background-color: #009B2f;
    color: white;
    font-family: "mukta";
}

.login-btn:hover {
    background-color: #fa5004;
}

.bth {
    color: white;
    font-size: 9px;
}

.bth span {
    color: #fa5004;

}


/* <--- Login Modal CSS---> */


.login-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: none;

}



.login-modal-container {
    position: absolute;
    width: 470px;
    height: fit-content;

    background-color: black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 999999;
    border: none;

}


.Info-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: none;

}



.Info-modal-container {
    position: absolute;
    width: 400px;
    height: 350px;

    background-color: black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 999999;
    border: none;

}