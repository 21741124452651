.sb-h {
  border-bottom: 1px solid #43CEED;
  font-size: 14px;
  color: #43CEED;
  font-family: "mukta";
  font-weight: 600;
}

.side-bar {
  background-color: #1a1a1a;
  height: 100vh;
  overflow-y: auto;
  margin-top: 66px;
  padding-bottom: 50px;
}

.side-bar::-webkit-scrollbar {
  display: none;
}

.sm-side-bar {

  top: 11%;
  z-index: 999999;

}

.list-group {
  background-color: #202020;

}

.list-group-item {
  background-color: #000000;
  border-radius: 15px;
  /* margin-left: 20px; */
  margin: 10px 10px 0px 10px;
  border: none !important;
  /* border-bottom: 1px solid grey !important; */
  color: white !important;
  font-size: 14px;
  font-family: "mukta";
  font-weight: 600;
  height: 42px;

}
.list-group-item:first-child {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.list-group-item:last-child {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

/* .list-group-item:hover {
  
  transition: .5s ease;
  border-left: 2px solid #fa5004 !important;
} */

.side-icon {
  height: 20px;
  color: white !important;
  /* background-color: white; */
  border: none !important;
  opacity: 1;
}

.menu {
  background-color: #3b3838;
  color: white;
  font-family: "mukta";
  font-weight: 500;
  border-top: 1px solid grey;
  min-height: 39px;
  max-height: auto;
  font-size: 15px;
  transition: .5s ease;
  cursor: pointer;
}

.subMenu {
  height: auto;
  font-size: 15px;
  font-weight: 600;
  font-family: "mukta";
  border-top: 1px solid white;
  /* background-color: #333030; */
  padding: 5px;
  cursor: pointer;

}

.menu-icon {
  color: white;
  font-size: 19px;
  /* transition: all .2s ease-out; */
}

/* <---------------------------------->sdebardropdown>>>>>>>>>>>>>>>>>>>> */
.dropdown-btn {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}


/* <-------------------------------------------------------------------------------------------------->>>>>> */




@media screen and (max-width: 993.99px) {
  .sm-side-bar {
    top: 0px;
    width: 250px;
  }


}
@media (min-width: 320px) and (max-width: 576px) {
  .signin{
    width: 45%;
  }
  .registration{
    width: 45%;
  }
}