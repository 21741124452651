nav {
  /* border-bottom: 1px solid #fd740d !important; */
  background-color: #1a1a1a;

}

.nav-bg {
  background:#1a1a1a;
}
.blink-img-div1 {
  height: 42px;
  width: 65px;
}
.image-size1 {
  height: 53px;
  width: 50px;
}
.white-space-nowrap {
  white-space: nowrap;
}

.navbar {
  height: 12vh;
  position: fixed;
  top: 0;
  z-index: 99;

}

.navbar-brand {
  height: 70px;
}

@media only screen and (max-width: 600px) {
  .signin-btn {
    /* padding: 6px 20px; */
    height: 25px !important;
    width: 90% !important;
    border-radius: 6px;
    border: none !important;
    color: white;
    font-size: 16px;
    background: linear-gradient(to right, #1368de 0, #4a8ff0 15%, #548ee0 30%, #1567d8 55%, #2869c3 100%) !important;
  }

  .wallet-btn {
    height: 28px !important;
    width: 115px !important;
    border-radius: 6px;
    background: linear-gradient(to right, #f2d71a 0%, #ecb024 100%);
    /* background: linear-gradient(to right, #fa5004 0%, #fd740d 51%, #fa5004 100%); */
    color: white;
    border: none;
  }

  .schedule-btn {
    background: linear-gradient(to right, #f2d71a 0%, #ecb024 100%);
    height: 47px !important;
    text-align: center;
    width: 94px !important;
    color: white;
    font-size: 11px;
    border-radius: 5px;
  }

  .in-play-btn {
    background-color: #030303;
    height: unset !important;
    width: 112px !important;
    color: #fd7e14;
    border: 1px solid #fd7e14;
    font-size: 10px;
    border-radius: 5px;
  }
}

.signin-btn {
  /* padding: 6px 20px; */
  height: 36px;
  width: 90px;
  border-radius: 6px;
  border: none !important;
  color: white;
  font-size: 16px;
  background: linear-gradient(to right, #f2d71a 0%, #ecb024 100%);}

.wallet-btn {
  height: 36px;
  width: 140px;
  border-radius: 6px;
  background: linear-gradient(to right, #f2d71a 0%, #ecb024 100%);  color: white;
  border: none;

}
.single-sport-div1 {
  height: 80px;
  width: 63px;
  color: white !important;
  font-family: "mukta";
}
.nav-btn {
  /* background-color: rgb(82, 81, 81); */
  background-color: #333333;
  padding: 6px 10px;
  border-radius: 6px;
  border: none !important;
  color: white;
  height: 30px;
  /* margin-top: 12px; */
  font-size: 13px;
  margin-left: 4px;
  font-family: "mukta";
  font-weight: 600;
  text-decoration: none;

}

.nav-btn:hover {
  background: linear-gradient(to right, #fa5004 0%, #fd740d 51%, #fa5004 100%);
}

.nav-btn.is-active {
  background: linear-gradient(to right, #f2d71a 0%, #ecb024 100%);
}



/* Dropdown Button */
.dropbtn {
  background-color: transparent;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;

}


.dropbtn:hover,
.dropbtn:focus {}


.dropdown {
  position: relative;
  display: inline-block;

}


.dropdown-content {
  /* display: none; */
  position: absolute;
  right: 30%;
  background-color: #1a1a1a;
  /* min-width: 170px; */
  /* height: 70px; */
  font-size: 16px;
  font-weight: 600px;
  /* height: 20px; */
  white-space: nowrap;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: rgb(255, 255, 255);
  padding: 9px 20px;
  text-decoration: none;
  font-size: 12px;
  display: block;
  border: 1px solid black;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #1ea9c9;
}


.dropdown-content svg {
  color: #fff;
  /* padding: 9px 20px; */

  font-size: 16px;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  /* background-color: #2980b9; */
}

.person-icon-div {
  background-color: #fa5004;
  border-radius: 17px;
  color: black;
  height: 28px;
  width: 28px;

}
