.poker-6player .casino-table {
  flex-direction: column;
  background-color: transparent;
}

.poker-6player .tab-content {
  background-color: rgba(0, 0, 0, 0.05);
}

.poker-6player .casinooddsbox {
  border: none;
  padding: 0 !important;
}

.poker-6player .casino6poker-item {
  color: #ffffff !important;
  background-color: #08c !important;
  width: 100% !important;
  height: 30;
  border-radius: 4px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
  border: 0 !important;
  padding: 4px 10px !important;
  cursor: pointer !important;
  font-size: 16px !important;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.poker-6player .casino-nation-name {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
}

.poker-6player .patern-name .card-icon {
  font-size: 16px;
}

.poker-6player .patern-name img {
  height: 16px;
}

.poker-6player .poker6-odds {
  color: #060606;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  padding-left: 5px;
}

.poker-6player .poker6-odds p {
  font-size: 17px;
}

.poker-6player .poker6-odds span {
  font-weight: 400;
}
.rowpoker {
  --bs-gutter-x: 0.5rem;
}
.mainhomeDev{
  margin-top: 90px;
}
