/* Custom CSS to override background colors */
.carousel .slider-wrapper,
.carousel .slide,
.carousel.carousel-slider .control-arrow,
.carousel.carousel-slider .control-dots .dot {
  background-color: black;
}

.carousel.carousel-slider .control-arrow {
  opacity: 0.5;
  transition: opacity 0.25s;
}

.carousel.carousel-slider .control-arrow:hover {
  opacity: 1; 
} 

/* 
.carousel_items {
  display: flex;
  overflow: hidden;
}
.carousel_item {
  position: relative;
  min-width: 100%;
  height: 100vh;
  transition: all 0.5s linear;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.carousel_text {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%);
  padding: 0.5rem 1rem;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-shadow: 1px 1px black;
  font-size: calc(1.5rem + 0.3vw);
  font-weight: bolder;
} */
