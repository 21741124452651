.casinocard-div {
    width: 180px;
    background-color: #202020;
    border-radius: 5px;
    border: none;

}

.maincardD .small-card-img {
    border-radius: 50%;
}

.maincardD {
    width: 150px;
    height: 150px;
    margin: auto;
}

.maincardD .small-card-img {
    width: 100%;
    height: 100%;
}

.live-casino-main-div {}

.overlaySmallCard {
    position: absolute;
    bottom: 0;
    overflow: hidden;
    left: 0;
    right: 0;
    height: 0;
    background-color: rgba(58, 54, 54, 0.75);
    border-radius: 5px;


}

.overlaySmallCard p {
    color: #fa5004;
    font: "mukta";
    font-size: 14px;
    font-weight: 600;
    /* margin-left: 36px; */
    margin-top: 60px;
}

.casinocard-div:hover {

    /* translate: 0px 15px 0px; */
    transition: .5s ease;
}

.casinocard-div:hover .overlaySmallCard {
    height: 100%;
    width: 100%;
    border-radius: 5px;


}

@media screen and (max-width: 550px) {
    .casinocard-div {
        width: 180px;
        background-color: #202020;
        border-radius: 5px;
        border: none;

    }


}

@media screen and (max-width: 450px) {
    .maincardD {
        width: 150px;
        height: 150px;
        margin: auto;
        background-color: #202020;
        border-radius: 5px;
        border: none;
    }
    .mainrowD{
        justify-content: center;
    }

    .he {
        overflow: scroll;
        height: 504px;
    }
}
.mainhomeDev{
    margin-top: 90px;
}
