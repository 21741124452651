.Baccarat .baccarat-main-odds {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.firstcol {
  width: 16%;
  margin-right: 2px;
}
.firstcol .cardinne {
  background-color: #086cb8;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  text-align: left;
  color: #fff;
  padding: 10px;
  height: 70px;
  font-size: 14px;
  line-height: 17px;
}
.lastcol {
  width: 16%;
  margin-left: 2px;
}
.lastcol .cardinne {
  background-color: #ae2130;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  text-align: left;
  color: #fff;
  padding: 10px;
  height: 70px;
  font-size: 14px;
  line-height: 17px;
}
.firstcol .pair {
  font-size: 13px;
  color: white;
}
.firstcol .number {
  font-size: 13px;
  color: white;
}
.middlecol {
  width: 66%;
  display: flex;
}
.middlecol .col-first,
.col-second,
.col-thierd {
  width: 100%;
  overflow: hidden;
}
.middlecol .col-first {
  background-color: #086cb8;
  text-align: left;
  color: #fff;
  padding: 10px;
  height: 70px;
}
.middlecol .col-second {
  background-color: #279532;
  color: #fff;
  padding: 10px;
  height: 70px;
}
.middlecol .col-thierd {
  background-color: #ae2130;
  text-align: left;
  color: #fff;
  padding: 10px;
  height: 70px;
}
.middlecol .middletext {
  font-size: 14px;
  color: white;
}
.middlecol .middletext .imagecard {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.suspended-box {
  position: relative;
  pointer-events: none;
  cursor: none;
}

.suspended-box-first {
  position: relative;
  pointer-events: none;
  cursor: none;
}

.suspended-box-last {
  position: relative;
  pointer-events: none;
  cursor: none;
}

.suspended-box::before {
  background-image: url(../../../src/Media/lock.svg);
  background-size: 17px 17px;
  filter: invert(1);
  -webkit-filter: invert(1);
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  pointer-events: none;
}

.suspended-box-first::before {
  background-image: url(../../../src/Media/lock.svg);
  background-size: 17px 17px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  filter: invert(1);
  -webkit-filter: invert(1);
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  pointer-events: none;
}

.suspended-box-last::before {
  background-image: url(../../../src/Media/lock.svg);
  background-size: 17px 17px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  filter: invert(1);
  -webkit-filter: invert(1);
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  pointer-events: none;
}

.suspended-box::after {
  content: "";
  background-color: #373636d6;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.suspended-box-first::after {
  content: "";
  background-color: #373636d6;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.suspended-box-last::after {
  content: "";
  background-color: #373636d6;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.textas{
  display: flex;
}
.below-text {
  font-size: 12px;
  color: #333;
  text-align: center;
}
.textbahar{
  position: relative;
  top: 10px;
  z-index: 99999;
}
.mainhomeDev{
  margin-top: 90px;
}
