
/* ::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: #010c4e;
} */



/* a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}

.fs-14 {
  font-size: 14px;
}

* > ul::-webkit-scrollbar {
  height: 0px !important;
}

* > ul li::marker {
  color: #001ebe;
} */

.middlesectionnew .middlecard {
    background-color: #000;
  }
  
  .middlesectionnew .middlecard .card-button {
    border: 1px solid #b8802e;
    border-radius: 4px;
    background-color: #303030;
  }
  
  .middlesectionnew .middlecard .card-button .buttonlist {
    padding-left: 0;
    display: flex;
    align-items: center;
    gap: 0.1rem;
  }
  
  .middlesectionnew .middlecard .card-button .buttonlist li {
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 39px;
    height: 39px;
  }
  
  .middlesectionnew .middlecard .card-button .buttonlist > :nth-child(2),
  .middlesectionnew .middlecard .card-button .buttonlist > :last-child {
    background-color: #e42d40;
  }
  
  .middlesectionnew .middlecard .card-button .idseide .roundid {
    font-size: 13px;
    color: #fff;
    font-weight: 600;
    white-space: nowrap;
  }
  
  .middlesectionnew .middlecard .card-button .idseide .idnumber {
    font-size: 15px;
    color: #fff;
    font-weight: 600;
  }
  
  .middlesectionnew .middlecard .card-button .playerHistorybtn {
    background-color: #b8802e;
    color: #fff;
    font-size: 14px;
    text-align: center;
  }
  
  .middlesectionnew .bottomcard {
    background: url(../../Media/Roulette/odds-bg-dark.svg) 50%;
    background-color: var(--accordian-bg);
    border-radius: 5px;
    display: block;
    padding-bottom: 10px;
  }
  
  .middlesectionnew .bottomcard .leftside .lastwin {
    color: #ab7025;
    font-size: 15px;
    font-weight: 600;
  }
  
  .middlesectionnew .bottomcard .leftside .total {
    font-size: 15px;
    color: #fff;
    font-weight: 600;
    text-align: center;
  }
  
  .middlesectionnew .bottomcard .rightside .totalbat {
    color: #ab7025;
    font-size: 15px;
    font-weight: 600;
  }
  
  .middlesectionnew .bottomcard .rightside .total {
    font-size: 15px;
    color: #fff;
    font-weight: 600;
    text-align: center;
  }
  
  .middlesectionnew .bottomcard .middleicon {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    list-style: none;
    justify-content: center;
    background-color: #000;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
  }
  
  .middlesectionnew .bottomcard .middleicon li {
    width: 35px;
    -o-object-fit: fill;
    object-fit: fill;
    height: 35px;
    background-size: 100% 100%;
    position: relative;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
  }
  
  .middlesectionnew .bottomcard .middleicon li:hover {
    transform: translateY(-10px);
  }
  
  .middlesectionnew .bottomcard .middleicon .icon1 {
    background-image: url(../../Media/Roulette/icon1.svg);
  }
  
  .middlesectionnew .bottomcard .middleicon .icon2 {
    background-image: url(../../Media/Roulette/icon2.svg);
  }
  
  .middlesectionnew .bottomcard .middleicon .icon3 {
    background-image: url(../../Media/Roulette/icon3.svg);
    /* height: 60px;
    width: 60px; */
  }
  
  .middlesectionnew .bottomcard .middleicon .icon4 {
    background-image: url(../../Media/Roulette/icon4.svg);
  }
  
  .middlesectionnew .bottomcard .middleicon .icon5 {
    background-image: url(../../Media/Roulette/icon5.svg);
  }
  
  .middlesectionnew .bottomcard .middleicon .icon6 {
    background-image: url(../../Media/Roulette/icon6.svg);
  }
  
  .middlesectionnew .bottomcard .buttoncontent {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    justify-content: center;
  }
  
  .middlesectionnew .bottomcard .buttoncontent .button {
    border: 1px solid #cf8d2e;
    border-radius: 6px;
    background-color: #000;
    color: #fff;
    padding: 5px 8px;
  }
  
  .middlesectionnew .bottomcard .buttoncontent .button .imagebtn {
    width: 20px;
    height: 20px;
    margin: auto;
  }
  
  .middlesectionnew .bottomcard .buttoncontent .button .btntext {
    margin-top: 3px;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .innercard {
    background-color: #111;
  }
  
  .roul-box {
    margin: 0 10px;
    pointer-events: visible;
    position: relative;
  }
  
  .place-bets {
    pointer-events: visible;
  }
  
  .no-bets {
    pointer-events: none;
  }
  
  .roulette-ctn {
    background-clip: padding-box, border-box;
    background-image: linear-gradient(#d9d9d9, hsla(0, 0%, 85%, 0.4)),
      linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0.4));
    background-origin: border-box;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
  }
  
  .roulette-ctn .winning_lines {
    height: 0;
    position: absolute;
    width: 100%;
  }
  
  .roulette-ctn .winning_lines #d_sb {
    position: absolute;
  }
  
  .roulette-ctn .winning_lines .double_s {
    height: 10px;
    margin-left: 6.7%;
    position: relative;
    top: 122px;
    width: 100%;
  }
  
  .roulette-ctn .winning_lines .double_s .dsblock {
    cursor: pointer;
    display: inline-block;
    height: 15px;
    position: relative;
    width: 2.5%;
    z-index: 3;
  }
  
  .roulette-ctn .winning_lines .double_s #ddss_1,
  .roulette-ctn .winning_lines .double_s #ddss_10,
  .roulette-ctn .winning_lines .double_s #ddss_11,
  .roulette-ctn .winning_lines .double_s #ddss_2,
  .roulette-ctn .winning_lines .double_s #ddss_3,
  .roulette-ctn .winning_lines .double_s #ddss_4,
  .roulette-ctn .winning_lines .double_s #ddss_5,
  .roulette-ctn .winning_lines .double_s #ddss_6,
  .roulette-ctn .winning_lines .double_s #ddss_7,
  .roulette-ctn .winning_lines .double_s #ddss_8,
  .roulette-ctn .winning_lines .double_s #ddss_9 {
    margin-left: 4.5%;
  }
  
  .roulette-ctn .winning_lines .double_s .chip {
    left: -3px;
    top: -6px;
  }
  
  .roulette-ctn .winning_lines .ttbbetblock {
    cursor: pointer;
    display: inline-block;
    height: 10px;
    margin-left: 4.2%;
    position: relative;
    top: -4px;
    width: 2.8%;
    z-index: 3;
  }
  
  .roulette-ctn .winning_lines .chip {
    height: 25px;
    position: absolute;
    width: 25px;
  }
  
  .roulette-ctn .winning_lines .chipSpan {
    color: #fff;
    display: block;
    font-family: sans-serif;
    font-size: 8px;
    font-weight: 700;
    position: relative;
    text-align: center;
    top: 8px;
  }
  
  .roulette-ctn .winning_lines .wlttb {
    height: 10px;
    margin-left: 6%;
    position: relative;
    top: 30px;
    width: 100%;
  }
  
  .roulette-ctn .winning_lines .wlttb .chip {
    left: -4px;
    position: absolute;
    top: -6px;
  }
  
  .roulette-ctn .winning_lines #wlttb_1 {
    margin-top: 1%;
  }
  
  .roulette-ctn .winning_lines #wlttb_2 {
    top: 62px;
  }
  
  .roulette-ctn .winning_lines #wlttb_3 {
    top: 96px;
  }
  
  .roulette-ctn .winning_lines .wlrtl {
    display: inline-block;
    height: 140px;
    margin-top: -6%;
    position: relative;
    width: 1.4%;
  }
  
  .roulette-ctn .winning_lines .rtlbb1,
  .roulette-ctn .winning_lines .rtlbb2,
  .roulette-ctn .winning_lines .rtlbb3 {
    height: 15px;
    margin-top: 185%;
    position: relative;
    width: 100%;
    z-index: 3;
  }
  
  .roulette-ctn .winning_lines .rtlbb1 .chip,
  .roulette-ctn .winning_lines .rtlbb2 .chip,
  .roulette-ctn .winning_lines .rtlbb3 .chip {
    margin-left: -7px;
    position: absolute;
    top: -6px;
  }
  
  .roulette-ctn .winning_lines .rtlbb2,
  .roulette-ctn .winning_lines .rtlbb3 {
    margin-top: 27px;
  }
  
  .roulette-ctn .winning_lines #wlrtl_0 {
    margin-left: 7.2%;
  }
  
  .roulette-ctn .winning_lines #wlrtl_1,
  .roulette-ctn .winning_lines #wlrtl_10,
  .roulette-ctn .winning_lines #wlrtl_11,
  .roulette-ctn .winning_lines #wlrtl_12,
  .roulette-ctn .winning_lines #wlrtl_2,
  .roulette-ctn .winning_lines #wlrtl_3,
  .roulette-ctn .winning_lines #wlrtl_4,
  .roulette-ctn .winning_lines #wlrtl_5,
  .roulette-ctn .winning_lines #wlrtl_6,
  .roulette-ctn .winning_lines #wlrtl_7,
  .roulette-ctn .winning_lines #wlrtl_8,
  .roulette-ctn .winning_lines #wlrtl_9 {
    margin-left: 5.6%;
  }
  
  .roulette-ctn .winning_lines .wlcb {
    height: 15px;
    margin-left: 6.7%;
    position: absolute;
    top: 35px;
    width: 100%;
  }
  
  .roulette-ctn .winning_lines .cbbb {
    cursor: pointer;
    display: inline-block;
    height: 15px;
    position: relative;
    width: 2.5%;
    z-index: 3;
  }
  
  .roulette-ctn .winning_lines .cbbb .chip {
    left: -4px;
    top: -4px;
  }
  
  .roulette-ctn .winning_lines #cbbb_1,
  .roulette-ctn .winning_lines #cbbb_10,
  .roulette-ctn .winning_lines #cbbb_11,
  .roulette-ctn .winning_lines #cbbb_13,
  .roulette-ctn .winning_lines #cbbb_14,
  .roulette-ctn .winning_lines #cbbb_15,
  .roulette-ctn .winning_lines #cbbb_16,
  .roulette-ctn .winning_lines #cbbb_17,
  .roulette-ctn .winning_lines #cbbb_18,
  .roulette-ctn .winning_lines #cbbb_19,
  .roulette-ctn .winning_lines #cbbb_2,
  .roulette-ctn .winning_lines #cbbb_20,
  .roulette-ctn .winning_lines #cbbb_21,
  .roulette-ctn .winning_lines #cbbb_22,
  .roulette-ctn .winning_lines #cbbb_23,
  .roulette-ctn .winning_lines #cbbb_3,
  .roulette-ctn .winning_lines #cbbb_4,
  .roulette-ctn .winning_lines #cbbb_5,
  .roulette-ctn .winning_lines #cbbb_6,
  .roulette-ctn .winning_lines #cbbb_7,
  .roulette-ctn .winning_lines #cbbb_8,
  .roulette-ctn .winning_lines #cbbb_9 {
    margin-left: 4.5%;
  }
  
  .roulette-ctn .winning_lines #wlcb_2 {
    top: 76px;
  }
  
  .roulette-ctn .zero-ctn {
    align-items: center;
    background: #009378;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    color: #fff;
    display: flex;
    font-family: sans-serif;
    font-size: 15px;
    font-weight: 700;
    justify-content: center;
    position: relative;
    width: 8%;
  }
  
  .roulette-ctn .inside-color,
  .roulette-ctn .zero-ctn:hover {
    opacity: 0.3;
  }
  
  .roulette-ctn .match-odds-ctn {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 84%;
  }
  
  .roulette-ctn .match-odds-ctn .first-column {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  
  .roulette-ctn .match-odds-ctn .col {
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0);
    -o-border-image: linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0.4));
    border-image: linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0.4));
    border-image-slice: 1;
    border-right: none;
    border-top: none;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Roboto Condensed, sans-serif;
    font-size: 15px;
    font-weight: 700;
    justify-content: center;
    padding: 10px 2px;
    text-align: center;
    width: 8.33%;
  }
  
  .roulette-ctn .match-odds-ctn .col .single-bet {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 30px;
  }
  
  .roulette-ctn .match-odds-ctn .col .single-bet .chip {
    display: inline-block;
  }
  
  .roulette-ctn .match-odds-ctn .col:nth-child(12) {
    border-right: 0.56px solid #d9d9d9;
  }
  
  .roulette-ctn .match-odds-ctn .col-Red {
    background-color: #e42d40;
  }
  
  .roulette-ctn .match-odds-ctn .col-Black {
    background-color: #000;
  }
  
  .roulette-ctn .match-odds-ctn .col-Black:hover,
  .roulette-ctn .match-odds-ctn .col-Red:hover,
  .roulette-ctn .match-odds-ctn .even-num-color,
  .roulette-ctn .match-odds-ctn .inside-color,
  .roulette-ctn .match-odds-ctn .num-change,
  .roulette-ctn .match-odds-ctn .odd-num-color,
  .roulette-ctn .match-odds-ctn .range-color,
  .roulette-ctn .match-odds-ctn .red-blck-change,
  .roulette-ctn .match-odds-ctn .row-color-change {
    opacity: 0.3;
  }
  
  .roulette-ctn .columns-ctn {
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    width: 8%;
  }
  
  .roulette-ctn .columns-ctn .columns-layer {
    background: #000;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    -o-border-image: linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0.4));
    border-image: linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0.4));
    border-image-slice: 1;
    color: #fff;
  }
  
  .roulette-ctn .columns-ctn .columns-layer .columns {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    font-size: 15px;
    font-weight: 700;
    justify-content: center;
    padding: 10px;
    text-align: center;
  }
  
  .roulette-ctn .columns-ctn .columns-layer:hover {
    opacity: 0.3;
  }
  
  .roulette-ctn .columns-ctn .columns-layer:first-child {
    border-top-right-radius: 30px;
  }
  
  .roulette-ctn .columns-ctn .columns-layer:nth-child(3) {
    border-bottom: none;
    border-bottom-right-radius: 28px;
  }
  
  .dozens-layer {
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .dozens-layer .dozens-ctn {
    background: #4f4f4f;
    display: flex;
    flex-direction: row;
    width: 84%;
  }
  
  .dozens-layer .dozens-ctn .dozens {
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0);
    -o-border-image: linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0));
    border-image: linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0));
    border-image-slice: 1;
    border-right: none;
    border-top: none;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    font-size: 15px;
    font-weight: 700;
    justify-content: center;
    padding: 10px;
    text-align: center;
    width: 33.3%;
  }
  
  .dozens-layer .dozens-ctn .dozens:nth-child(3) {
    border-right: 0.56px solid #d9d9d9;
  }
  
  .dozens-layer .dozens-ctn .dozens:hover {
    opacity: 0.3;
  }
  
  .last-row-ctn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .last-row-ctn .last-row {
    background: #4f4f4f;
    display: flex;
    flex-direction: row;
    width: 84%;
  }
  
  .last-row-ctn .last-row .even,
  .last-row-ctn .last-row .high,
  .last-row-ctn .last-row .low,
  .last-row-ctn .last-row .odd {
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0);
    -o-border-image: linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0));
    border-image: linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0));
    border-image-slice: 1;
    border-right: none;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Roboto Condensed, sans-serif;
    font-size: 15px;
    font-weight: 700;
    justify-content: center;
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase;
    width: 16.8%;
  }
  
  .last-row-ctn .last-row .high {
    border-right: 0.56px solid #d9d9d9;
  }
  
  .last-row-ctn .last-row .even:hover,
  .last-row-ctn .last-row .high:hover,
  .last-row-ctn .last-row .low:hover,
  .last-row-ctn .last-row .odd:hover {
    opacity: 0.3;
  }
  
  .last-row-ctn .last-row .red-black {
    display: flex;
    flex-direction: row;
    width: 33.2%;
  }
  
  .last-row-ctn .last-row .red-black .color-ctn {
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0);
    -o-border-image: linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0));
    border-image: linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0));
    border-image-slice: 1;
    border-right: none;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    font-size: 15px;
    font-weight: 700;
    justify-content: center;
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase;
    width: 50%;
  }
  
  .last-row-ctn .last-row .red-black .color-ctn:hover {
    opacity: 0.3;
  }
  
  .last-row-ctn .last-row .red-black .col-Red {
    background-color: #e42d40;
  }
  
  .last-row-ctn .last-row .red-black .col-Black {
    background-color: #000;
  }
  
  .switch-layer {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 0 10px;
    width: 100%;
  }
  
  .switch-layer .ure-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 55%;
  }
  
  .switch-layer .ure-btn .edit-st-ctn,
  .switch-layer .ure-btn .reset-ctn,
  .switch-layer .ure-btn .stats-ctn,
  .switch-layer .ure-btn .switch-ctn,
  .switch-layer .ure-btn .undo-ctn {
    align-items: center;
    background: var(--lt-bg-color);
    border: 1px solid #cf8d2e;
    border-radius: 6px;
    color: var(--chip-title);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-family: Roboto Condensed, sans-serif;
    font-size: 8px;
    font-weight: 700;
    justify-content: center;
    margin-right: 5px;
    padding: 1% 0;
    pointer-events: visible;
    width: 13%;
  }
  
  .switch-layer .ure-btn .reset-icon,
  .switch-layer .ure-btn .stats-icon,
  .switch-layer .ure-btn .undo-icon {
    height: 20px;
    width: 20px;
  }
  
  .switch-layer .ure-btn .edit-icon {
    height: 15px;
    width: 15px;
  }
  
  .switch-layer .ure-btn .e-text {
    margin-top: 3px;
    text-align: center;
  }
  
  .switch-layer .ure-btn .stats-ctn {
    margin-right: 0;
  }
  
  .t-l-btn {
    display: flex;
    flex-direction: row;
    margin-top: 3%;
    width: 100%;
  }
  
  .t-l-btn .last-win,
  .t-l-btn .total-bets {
    color: #b97f2b;
    font-family: Roboto Condensed, sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    width: 50%;
  }
  
  .t-l-btn .last-win .t-count,
  .t-l-btn .total-bets .t-count {
    color: var(--lt-text-color);
  }
  
  .t-l-btn .last-win {
    text-align: start;
  }
  
  .t-l-btn .total-bets {
    text-align: end;
  }
  
  /* .redChip {
    background: url(/static/media/red-chip.4ce2318e3b95b9819bc3.svg) 50%;
    background-size: cover;
  }
  
  .blueChip {
    background: url(/static/media/maroon-chip.710f6d2460b4f11fdbb0.svg) 50%;
    background-size: cover;
  }
  
  .greenChip {
    background: url(/static/media/green-chip.d074eb36fdc3076a115b.svg) 50%;
    background-size: cover;
  }
  
  .violetChip {
    background: url(/static/media/blue-chip.aeef0d487ca9055a09fd.svg) 50%;
    background-size: cover;
  }
  
  .pinkChip {
    background: url(/static/media/purple-chip.e399df8a2988c15f5d4f.svg) 50%;
    background-size: cover;
  }
  
  .purpleChip {
    background: url(/static/media/pink-chip.ab1ff2607f4b0642f20b.svg) 50%;
    background-size: cover;
  } */
  
  .orangeChip {
    border-color: orange !important;
  }
  
  .goldChip {
    border-color: gold !important;
  }
  
  .roul-accordian {
    background: url(https://rg-assests.royalgaming.online/common/odds-bg-light.svg)
      50%;
    background-color: var(--white-color);
  }
  
  .switch-layer .chipDeck .chip-ctn .show-stakes {
    background-clip: padding-box, border-box;
    background-image: linear-gradient(180deg, #e6ede6 100%, #f9faf9 0, #f9fbf7 0),
      linear-gradient(90deg, #000, hsla(0, 0%, 82%, 0), #ececec) !important;
    background-origin: border-box;
    border: 2px double rgba(0, 0, 0, 0);
    border-radius: 100px;
    opacity: 1 !important;
    width: 100% !important;
  }
  
  @media (max-width: 767.98px) {
    .roul-box {
      flex-direction: row;
      display: flex;
    }
  
    .roul-accordian .chip-odds-view-ctn .chip-odds-view-layer .t-l-ctn {
      justify-content: space-between;
      width: 33%;
    }
  
    .roul-accordian .chip-odds-view-ctn .chip-odds-view-layer .t-l-ctn .last-win {
      margin-left: -5px;
    }
  
    .roul-accordian .rol-chip-view {
      padding: 10px 16px 16px;
    }
  
    .chips-stats {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-weight: 700;
      margin-top: 30px;
    }
  
    .switch-layer {
      margin: 0;
      width: 80%;
    }
  
    .switch-layer .chipDeck {
      margin: 8px 0;
      width: 100%;
    }
  
    .m-t-l-btn {
      align-items: center;
      background: var(--lt-bg-color);
      border: 1px solid #cf8d2e;
      border-radius: 6px;
      color: var(--lt-text-color);
      display: flex;
      flex-direction: row;
      font-size: 12px;
      justify-content: center;
      width: 35%;
    }
  
    .m-t-l-btn .last-win,
    .m-t-l-btn .total-bets {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
    }
  
    .m-t-l-btn .last-win .text,
    .m-t-l-btn .total-bets .text {
      color: #cf8d2e;
    }
  
    .m-t-l-btn .last-win {
      border-right: 1px solid #cf8d2e;
    }
  
    .odd-value .od-value,
    .odd-value .odds {
      font-size: 8px;
    }
  
    .odd-value .chip {
      height: 30px;
      width: 30px;
    }
  
    .odd-value .chipSpan {
      top: 9px;
    }
  
    .roul-betslip {
      margin-bottom: 10px;
    }
  
    .rou-mob-ctn {
      display: flex;
      flex-direction: row;
    }
  
    .rou-mob-ctn .MuiBackdrop-root {
      z-index: 1200;
    }
  
    .chipDeck .chip-ctn {
      width: 100%;
    }
  
    .r-name {
      transform: rotate(90deg);
      width: 200%;
    }
  
    .roulette-ctn {
      border-radius: 30px;
      flex-direction: column;
      height: 100%;
      width: 70%;
      order: 2;
    }
  
    .roulette-ctn .winning_lines {
      height: 86%;
      margin-top: 41px;
      position: absolute;
      width: 70%;
    }
  
    .roulette-ctn .winning_lines .chip {
      height: 25px;
      width: 25px;
    }
  
    .roulette-ctn .winning_lines .chipSpan {
      top: 7px;
    }
  
    .roulette-ctn .winning_lines #wlttb_1 {
      margin-left: 61.8%;
      margin-top: 0;
    }
  
    .roulette-ctn .winning_lines #wlttb_2 {
      margin-left: -43.5%;
      top: 0;
    }
  
    .roulette-ctn .winning_lines #wlttb_3 {
      margin-left: -43.2%;
      top: 0;
    }
  
    .roulette-ctn .winning_lines .wlttb {
      display: inline-block;
      height: 100%;
      margin-left: 0;
      top: 0;
      width: 10%;
    }
  
    .roulette-ctn .winning_lines .wlttb .chip {
      left: 0;
      top: 0;
    }
  
    .roulette-ctn .winning_lines .ttbbetblock {
      display: inline-block;
      height: 4%;
      margin-bottom: 18.2px;
      margin-left: 0;
      top: 2%;
      width: 100%;
    }
  
    .roulette-ctn .winning_lines #wlrtl_0 {
      margin-left: 0;
    }
  
    .roulette-ctn .winning_lines .wlrtl {
      display: block;
      height: 5%;
      margin-bottom: 17.6px;
      margin-left: 0 !important;
      margin-top: 0;
      top: -102.2%;
      width: 100%;
    }
  
    .roulette-ctn .winning_lines #wlrtl_1,
    .roulette-ctn .winning_lines #wlrtl_10,
    .roulette-ctn .winning_lines #wlrtl_11,
    .roulette-ctn .winning_lines #wlrtl_12,
    .roulette-ctn .winning_lines #wlrtl_2,
    .roulette-ctn .winning_lines #wlrtl_3,
    .roulette-ctn .winning_lines #wlrtl_4,
    .roulette-ctn .winning_lines #wlrtl_5,
    .roulette-ctn .winning_lines #wlrtl_6,
    .roulette-ctn .winning_lines #wlrtl_7,
    .roulette-ctn .winning_lines #wlrtl_8,
    .roulette-ctn .winning_lines #wlrtl_9 {
      margin-left: 0;
    }
  
    .roulette-ctn .winning_lines .rtlbb1 {
      margin-left: 78%;
    }
  
    .roulette-ctn .winning_lines .rtlbb2,
    .roulette-ctn .winning_lines .rtlbb3 {
      margin-left: -43%;
    }
  
    .roulette-ctn .winning_lines .rtlbb1,
    .roulette-ctn .winning_lines .rtlbb2,
    .roulette-ctn .winning_lines .rtlbb3 {
      display: inline-block;
      height: 100%;
      margin-top: 0;
      top: 0;
      width: 10%;
    }
  
    .roulette-ctn .winning_lines .rtlbb1 .chip,
    .roulette-ctn .winning_lines .rtlbb2 .chip,
    .roulette-ctn .winning_lines .rtlbb3 .chip {
      margin-left: 0;
      top: 0;
    }
  
    .roulette-ctn .winning_lines .wlcb {
      display: inline-block;
      height: 100%;
      top: 0 !important;
      width: 10%;
    }
  
    .roulette-ctn .winning_lines #wlcb_1 {
      margin-left: 61.8%;
    }
  
    .roulette-ctn .winning_lines #wlcb_2 {
      margin-left: 28.5%;
    }
  
    .roulette-ctn .winning_lines #cbbb_1,
    .roulette-ctn .winning_lines #cbbb_10,
    .roulette-ctn .winning_lines #cbbb_11,
    .roulette-ctn .winning_lines #cbbb_13,
    .roulette-ctn .winning_lines #cbbb_14,
    .roulette-ctn .winning_lines #cbbb_15,
    .roulette-ctn .winning_lines #cbbb_16,
    .roulette-ctn .winning_lines #cbbb_17,
    .roulette-ctn .winning_lines #cbbb_18,
    .roulette-ctn .winning_lines #cbbb_19,
    .roulette-ctn .winning_lines #cbbb_2,
    .roulette-ctn .winning_lines #cbbb_20,
    .roulette-ctn .winning_lines #cbbb_21,
    .roulette-ctn .winning_lines #cbbb_22,
    .roulette-ctn .winning_lines #cbbb_23,
    .roulette-ctn .winning_lines #cbbb_3,
    .roulette-ctn .winning_lines #cbbb_4,
    .roulette-ctn .winning_lines #cbbb_5,
    .roulette-ctn .winning_lines #cbbb_6,
    .roulette-ctn .winning_lines #cbbb_7,
    .roulette-ctn .winning_lines #cbbb_8,
    .roulette-ctn .winning_lines #cbbb_9 {
      margin-left: 0;
    }
  
    .roulette-ctn .winning_lines .cbbb {
      display: inline-block;
      height: 5%;
      margin-bottom: 12.6px;
      top: -2% !important;
      width: 100%;
    }
  
    .roulette-ctn .winning_lines .cbbb .chip {
      left: 0;
      top: 0;
    }
  
    .roulette-ctn .winning_lines .double_s {
      display: inline-block;
      height: 100%;
      margin-left: -5%;
      top: 0 !important;
      width: 10%;
    }
  
    .roulette-ctn .winning_lines .double_s .chip {
      left: 0;
      top: 0;
    }
  
    .roulette-ctn .winning_lines .double_s #ddss_1,
    .roulette-ctn .winning_lines .double_s #ddss_10,
    .roulette-ctn .winning_lines .double_s #ddss_11,
    .roulette-ctn .winning_lines .double_s #ddss_2,
    .roulette-ctn .winning_lines .double_s #ddss_3,
    .roulette-ctn .winning_lines .double_s #ddss_4,
    .roulette-ctn .winning_lines .double_s #ddss_5,
    .roulette-ctn .winning_lines .double_s #ddss_6,
    .roulette-ctn .winning_lines .double_s #ddss_7,
    .roulette-ctn .winning_lines .double_s #ddss_8,
    .roulette-ctn .winning_lines .double_s #ddss_9 {
      margin-left: 0 !important;
    }
  
    .roulette-ctn .winning_lines .double_s .dsblock {
      display: inline-block;
      height: 5%;
      margin-bottom: 12.6px;
      top: -2% !important;
      width: 100%;
    }
  
    .roulette-ctn .zero-ctn {
      border-bottom-left-radius: 0;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      padding: 10px;
      width: 100%;
    }
  
    .roulette-ctn .zero-ctn .value {
      font-family: Roboto Condensed, sans-serif;
      font-size: 16px;
      font-weight: 800;
    }
  
    .roulette-ctn .inside-color,
    .roulette-ctn .zero-ctn:hover {
      opacity: 1;
    }
  
    .roulette-ctn .match-odds-ctn {
      flex-direction: row;
      height: 90%;
      width: 100%;
    }
  
    .roulette-ctn .match-odds-ctn .first-column {
      flex-direction: column;
    }
  
    .roulette-ctn .match-odds-ctn .col {
      border: 1px solid rgba(0, 0, 0, 0);
      -o-border-image: linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0.4));
      border-image: linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0.4));
      border-image-slice: 1;
      border-right: none;
      font-family: Roboto Condensed, sans-serif;
      font-size: 16px;
      font-weight: 800;
      padding: 10px;
      width: 100%;
    }
  
    .roulette-ctn .match-odds-ctn .col .single-bet {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 30px;
    }
  
    .roulette-ctn .match-odds-ctn .col .single-bet .chip {
      display: inline-block;
    }
  
    .roulette-ctn .match-odds-ctn .col-Black:hover,
    .roulette-ctn .match-odds-ctn .col-Red:hover,
    .roulette-ctn .match-odds-ctn .even-num-color,
    .roulette-ctn .match-odds-ctn .inside-color,
    .roulette-ctn .match-odds-ctn .num-change,
    .roulette-ctn .match-odds-ctn .odd-num-color,
    .roulette-ctn .match-odds-ctn .range-color,
    .roulette-ctn .match-odds-ctn .red-blck-change,
    .roulette-ctn .match-odds-ctn .row-color-change {
      opacity: 1;
    }
  
    .roulette-ctn .columns-ctn {
      display: flex;
      flex-direction: row;
      height: 5%;
      width: 100%;
    }
  
    .roulette-ctn .columns-ctn .columns-layer {
      background: #4f4f4f;
      border-bottom: none;
      -o-border-image: linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0.4));
      border-image: linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0.4));
      border-image-slice: 1;
      border-right: 1px solid rgba(0, 0, 0, 0);
      width: 100%;
    }
  
    .roulette-ctn .columns-ctn .columns-layer .columns {
      font-family: Roboto Condensed, sans-serif;
      font-size: 16px;
      font-weight: 800;
      padding: 10px;
    }
  
    .roulette-ctn .columns-ctn .columns-layer:hover {
      opacity: 1;
    }
  
    .roulette-ctn .columns-ctn .columns-layer:first-child {
      border-bottom-left-radius: 30px;
      border-top-right-radius: 0;
    }
  
    .roulette-ctn .columns-ctn .columns-layer:nth-child(3) {
      border-bottom-right-radius: 30px;
      border-right: none;
    }
  
    .dozens-layer {
      align-items: center;
      width: 15%;
    }
  
    .dozens-layer .dozens-ctn {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 85%;
      width: 100%;
    }
  
    .dozens-layer .dozens-ctn .dozens {
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0);
      -o-border-image: linear-gradient(270deg, #d9d9d9, hsla(0, 0%, 85%, 0));
      border-image: linear-gradient(270deg, #d9d9d9, hsla(0, 0%, 85%, 0));
      border-image-slice: 1;
      border-right: none;
      display: flex;
      font-family: Roboto Condensed, sans-serif;
      font-size: 16px;
      font-weight: 800;
      height: 33.33%;
      justify-content: center;
      padding: 0;
      width: 100%;
    }
  
    .dozens-layer .dozens-ctn .dozens:hover {
      opacity: 1;
    }
  
    .last-row-ctn {
      align-items: center;
      margin-bottom: 0;
      width: 15%;
    }
  
    .last-row-ctn .last-row {
      flex-direction: column;
      height: 85%;
      width: 100%;
    }
  
    .last-row-ctn .last-row .even,
    .last-row-ctn .last-row .high,
    .last-row-ctn .last-row .low,
    .last-row-ctn .last-row .odd {
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0);
      -o-border-image: linear-gradient(270deg, #d9d9d9, hsla(0, 0%, 85%, 0));
      border-image: linear-gradient(270deg, #d9d9d9, hsla(0, 0%, 85%, 0));
      border-image-slice: 1;
      font-family: Roboto Condensed, sans-serif;
      font-size: 16px;
      font-weight: 800;
      height: 16.66%;
      justify-content: center;
      padding: 5px;
      text-transform: uppercase;
      width: 100%;
    }
  
    .last-row-ctn .last-row .even:hover,
    .last-row-ctn .last-row .high:hover,
    .last-row-ctn .last-row .low:hover,
    .last-row-ctn .last-row .odd:hover {
      opacity: 1;
    }
  
    .last-row-ctn .last-row .red-black {
      flex-direction: column;
      height: 33.33%;
      width: 100%;
    }
  
    .last-row-ctn .last-row .red-black .color-ctn {
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0);
      -o-border-image: linear-gradient(270deg, #d9d9d9, hsla(0, 0%, 85%, 0));
      border-image: linear-gradient(270deg, #d9d9d9, hsla(0, 0%, 85%, 0));
      border-image-slice: 1;
      display: flex;
      flex-direction: column;
      font-family: Roboto Condensed, sans-serif;
      font-size: 16px;
      font-weight: 800;
      height: 50%;
      justify-content: center;
      text-transform: uppercase;
      width: 100%;
    }
  
    .last-row-ctn .last-row .red-black .color-ctn:hover {
      opacity: 1;
    }
  }
  
  @media (max-width: 575.98px) {
    .middlesectionnew .middlecard .heading {
      font-size: 14px;
      font-weight: 600;
      color: #fff;
    }
  
    .middlesectionnew .middlecard .idnumber {
      font-size: 12px;
      color: #b97f2b;
      font-weight: 600;
    }
  
    .middlesectionnew .middlecard .idnumber span {
      color: #fff;
    }
  
    .middlesectionnew .middlecard .card-button {
      border-radius: 4px;
    }
  
    .middlesectionnew .middlecard .card-button .buttonlist {
      padding-left: 0;
      display: flex;
      align-items: center;
      gap: 0.1rem;
    }
  
    .middlesectionnew .middlecard .card-button .buttonlist li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 39px;
      height: 39px;
    }
  
    .middlesectionnew .middlecard .card-button .idseide .roundid {
      font-size: 14px;
    }
  
    .middlesectionnew .middlecard .card-button .idseide .idnumber {
      font-size: 15px;
    }
  
    .middlesectionnew .bottomcard {
      display: block;
      padding-bottom: 10px;
    }
  
    .middlesectionnew .bottomcard .leftside .lastwin {
      font-size: 12px;
      text-align: center;
    }
  
    .middlesectionnew .bottomcard .leftside .total {
      font-size: 12px;
      text-align: center;
    }
  
    .middlesectionnew .bottomcard .rightside .totalbat {
      font-size: 12px;
      text-align: center;
    }
  
    .middlesectionnew .bottomcard .rightside .total {
      font-size: 12px;
      text-align: center;
    }
  
    .middlesectionnew .bottomcard .middleicon {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      justify-content: center;
      margin: auto;
    }
  
    .middlesectionnew .bottomcard .middleicon li {
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .middlesectionnew .bottomcard .middleicon li:hover {
      transform: translateY(-10px);
      height: 50px;
      width: 50px;
    }
  
    .middlesectionnew .bottomcard .middleicon .icon3 {
      height: 40px;
      width: 40px;
    }
  
    .middlesectionnew .bottomcard .buttoncontent {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      justify-content: center;
    }
  
    .middlesectionnew .bottomcard .buttoncontent .button {
      padding: 4px 4px;
    }
  
    .middlesectionnew .bottomcard .buttoncontent .button .imagebtn {
      width: 16px;
      height: 16px;
      margin: auto;
    }
  
    .middlesectionnew .bottomcard .buttoncontent .button .btntext {
      margin-top: 4px;
      font-size: 10px;
    }
  
    .middlesectionnew .card-button {
      border: 1px solid #b8802e;
      border-radius: 4px;
      background-color: #303030;
    }
  
    .middlesectionnew .card-button .buttonlist {
      padding-left: 0;
      display: flex;
      align-items: center;
      gap: 0.1rem;
    }
  
    .middlesectionnew .card-button .buttonlist li {
      background-color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      width: 39px;
      height: 39px;
    }
  
    .middlesectionnew .card-button .buttonlist > :nth-child(2),
    .middlesectionnew .card-button .buttonlist > :last-child {
      background-color: #e42d40;
    }
  
    .middlesectionnew .card-button .idseide .roundid {
      font-size: 14px;
      color: #fff;
      font-weight: 600;
    }
  
    .middlesectionnew .card-button .idseide .idnumber {
      font-size: 15px;
      color: #fff;
      font-weight: 600;
    }
  
    .middlesectionnew .card-button .playerHistorybtn {
      background-color: #b8802e;
      color: #fff;
    }
  
    .roul-box {
      flex-direction: row;
      display: flex;
    }
  
    .roulette-ctn .winning_lines #wlttb_1 {
      margin-left: 61.1%;
    }
  
    .roulette-ctn .winning_lines #wlttb_2 {
      margin-left: -42.8%;
    }
  
    .roulette-ctn .winning_lines #wlcb_1 {
      margin-left: 61.1%;
    }
  
    .roulette-ctn .winning_lines .rtlbb1 {
      margin-left: 77.3%;
    }
  
    .rou-mob-ctn {
      display: flex;
      flex-direction: row;
    }
  
    .chip-odds-view-ctn .chip-odds-view-layer {
      justify-content: space-between;
      margin-bottom: 0;
      width: 100%;
    }
  
    .rou-mob-ctn {
      display: flex;
      flex-direction: row;
    }
  
    .roul-accordian .chip-odds-view-ctn .chip-odds-view-layer .t-l-ctn {
      justify-content: space-between;
      width: 33%;
    }
  
    .roul-accordian .chip-odds-view-ctn .chip-odds-view-layer .t-l-ctn .last-win {
      margin-left: -5px;
    }
  
    .roul-accordian .rol-chip-view {
      padding: 10px 16px 16px;
    }
  
    .chips-stats {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-weight: 700;
      margin-top: 30px;
    }
  
    .switch-layer {
      margin: 0;
      width: 80%;
    }
  
    .switch-layer .chipDeck {
      margin: 8px 0;
      width: 100%;
    }
  
    .m-t-l-btn {
      align-items: center;
      background: var(--lt-bg-color);
      border: 1px solid #cf8d2e;
      border-radius: 6px;
      color: var(--lt-text-color);
      display: flex;
      flex-direction: row;
      font-size: 12px;
      justify-content: center;
      width: 35%;
    }
  
    .m-t-l-btn .last-win,
    .m-t-l-btn .total-bets {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
    }
  
    .m-t-l-btn .last-win .text,
    .m-t-l-btn .total-bets .text {
      color: #cf8d2e;
    }
  
    .m-t-l-btn .last-win {
      border-right: 1px solid #cf8d2e;
    }
  
    .odd-value .od-value,
    .odd-value .odds {
      font-size: 8px;
    }
  
    .odd-value .chip {
      height: 30px;
      width: 30px;
    }
  
    .odd-value .chipSpan {
      top: 9px;
    }
  
    .roul-betslip {
      margin-bottom: 10px;
    }
  
    .rou-mob-ctn {
      display: flex;
      flex-direction: row;
    }
  
    .rou-mob-ctn .MuiBackdrop-root {
      z-index: 1200;
    }
  
    .chipDeck .chip-ctn {
      width: 100%;
    }
  
    .r-name {
      transform: rotate(90deg);
      width: 200%;
    }
  
    .roulette-ctn {
      border-radius: 30px;
      flex-direction: column;
      height: 100%;
      width: 70%;
      order: 2;
    }
  
    .roulette-ctn .winning_lines {
      height: 86%;
      margin-top: 41px;
      position: absolute;
      width: 70%;
    }
  
    .roulette-ctn .winning_lines .chip {
      height: 25px;
      width: 25px;
    }
  
    .roulette-ctn .winning_lines .chipSpan {
      top: 7px;
    }
  
    .roulette-ctn .winning_lines #wlttb_1 {
      margin-left: 61.8%;
      margin-top: 0;
    }
  
    .roulette-ctn .winning_lines #wlttb_2 {
      margin-left: -43.5%;
      top: 0;
    }
  
    .roulette-ctn .winning_lines #wlttb_3 {
      margin-left: -43.2%;
      top: 0;
    }
  
    .roulette-ctn .winning_lines .wlttb {
      display: inline-block;
      height: 100%;
      margin-left: 0;
      top: 0;
      width: 10%;
    }
  
    .roulette-ctn .winning_lines .wlttb .chip {
      left: 0;
      top: 0;
    }
  
    .roulette-ctn .winning_lines .ttbbetblock {
      display: inline-block;
      height: 4%;
      margin-bottom: 18.2px;
      margin-left: 0;
      top: 2%;
      width: 100%;
    }
  
    .roulette-ctn .winning_lines #wlrtl_0 {
      margin-left: 0;
    }
  
    .roulette-ctn .winning_lines .wlrtl {
      display: block;
      height: 5%;
      margin-bottom: 17.6px;
      margin-left: 0 !important;
      margin-top: 0;
      top: -102.2%;
      width: 100%;
    }
  
    .roulette-ctn .winning_lines #wlrtl_1,
    .roulette-ctn .winning_lines #wlrtl_10,
    .roulette-ctn .winning_lines #wlrtl_11,
    .roulette-ctn .winning_lines #wlrtl_12,
    .roulette-ctn .winning_lines #wlrtl_2,
    .roulette-ctn .winning_lines #wlrtl_3,
    .roulette-ctn .winning_lines #wlrtl_4,
    .roulette-ctn .winning_lines #wlrtl_5,
    .roulette-ctn .winning_lines #wlrtl_6,
    .roulette-ctn .winning_lines #wlrtl_7,
    .roulette-ctn .winning_lines #wlrtl_8,
    .roulette-ctn .winning_lines #wlrtl_9 {
      margin-left: 0;
    }
  
    .roulette-ctn .winning_lines .rtlbb1 {
      margin-left: 78%;
    }
  
    .roulette-ctn .winning_lines .rtlbb2,
    .roulette-ctn .winning_lines .rtlbb3 {
      margin-left: -43%;
    }
  
    .roulette-ctn .winning_lines .rtlbb1,
    .roulette-ctn .winning_lines .rtlbb2,
    .roulette-ctn .winning_lines .rtlbb3 {
      display: inline-block;
      height: 100%;
      margin-top: 0;
      top: 0;
      width: 10%;
    }
  
    .roulette-ctn .winning_lines .rtlbb1 .chip,
    .roulette-ctn .winning_lines .rtlbb2 .chip,
    .roulette-ctn .winning_lines .rtlbb3 .chip {
      margin-left: 0;
      top: 0;
    }
  
    .roulette-ctn .winning_lines .wlcb {
      display: inline-block;
      height: 100%;
      top: 0 !important;
      width: 10%;
    }
  
    .roulette-ctn .winning_lines #wlcb_1 {
      margin-left: 61.8%;
    }
  
    .roulette-ctn .winning_lines #wlcb_2 {
      margin-left: 28.5%;
    }
  
    .roulette-ctn .winning_lines #cbbb_1,
    .roulette-ctn .winning_lines #cbbb_10,
    .roulette-ctn .winning_lines #cbbb_11,
    .roulette-ctn .winning_lines #cbbb_13,
    .roulette-ctn .winning_lines #cbbb_14,
    .roulette-ctn .winning_lines #cbbb_15,
    .roulette-ctn .winning_lines #cbbb_16,
    .roulette-ctn .winning_lines #cbbb_17,
    .roulette-ctn .winning_lines #cbbb_18,
    .roulette-ctn .winning_lines #cbbb_19,
    .roulette-ctn .winning_lines #cbbb_2,
    .roulette-ctn .winning_lines #cbbb_20,
    .roulette-ctn .winning_lines #cbbb_21,
    .roulette-ctn .winning_lines #cbbb_22,
    .roulette-ctn .winning_lines #cbbb_23,
    .roulette-ctn .winning_lines #cbbb_3,
    .roulette-ctn .winning_lines #cbbb_4,
    .roulette-ctn .winning_lines #cbbb_5,
    .roulette-ctn .winning_lines #cbbb_6,
    .roulette-ctn .winning_lines #cbbb_7,
    .roulette-ctn .winning_lines #cbbb_8,
    .roulette-ctn .winning_lines #cbbb_9 {
      margin-left: 0;
    }
  
    .roulette-ctn .winning_lines .cbbb {
      display: inline-block;
      height: 5%;
      margin-bottom: 12.6px;
      top: -2% !important;
      width: 100%;
    }
  
    .roulette-ctn .winning_lines .cbbb .chip {
      left: 0;
      top: 0;
    }
  
    .roulette-ctn .winning_lines .double_s {
      display: inline-block;
      height: 100%;
      margin-left: -5%;
      top: 0 !important;
      width: 10%;
    }
  
    .roulette-ctn .winning_lines .double_s .chip {
      left: 0;
      top: 0;
    }
  
    .roulette-ctn .winning_lines .double_s #ddss_1,
    .roulette-ctn .winning_lines .double_s #ddss_10,
    .roulette-ctn .winning_lines .double_s #ddss_11,
    .roulette-ctn .winning_lines .double_s #ddss_2,
    .roulette-ctn .winning_lines .double_s #ddss_3,
    .roulette-ctn .winning_lines .double_s #ddss_4,
    .roulette-ctn .winning_lines .double_s #ddss_5,
    .roulette-ctn .winning_lines .double_s #ddss_6,
    .roulette-ctn .winning_lines .double_s #ddss_7,
    .roulette-ctn .winning_lines .double_s #ddss_8,
    .roulette-ctn .winning_lines .double_s #ddss_9 {
      margin-left: 0 !important;
    }
  
    .roulette-ctn .winning_lines .double_s .dsblock {
      display: inline-block;
      height: 5%;
      margin-bottom: 12.6px;
      top: -2% !important;
      width: 100%;
    }
  
    .roulette-ctn .zero-ctn {
      border-bottom-left-radius: 0;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      padding: 10px;
      width: 100%;
    }
  
    .roulette-ctn .zero-ctn .value {
      font-family: Roboto Condensed, sans-serif;
      font-size: 16px;
      font-weight: 800;
    }
  
    .roulette-ctn .inside-color,
    .roulette-ctn .zero-ctn:hover {
      opacity: 1;
    }
  
    .roulette-ctn .match-odds-ctn {
      flex-direction: row;
      height: 90%;
      width: 100%;
    }
  
    .roulette-ctn .match-odds-ctn .first-column {
      flex-direction: column;
    }
  
    .roulette-ctn .match-odds-ctn .col {
      border: 1px solid rgba(0, 0, 0, 0);
      -o-border-image: linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0.4));
      border-image: linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0.4));
      border-image-slice: 1;
      border-right: none;
      font-family: Roboto Condensed, sans-serif;
      font-size: 16px;
      font-weight: 800;
      padding: 10px;
      width: 100%;
    }
  
    .roulette-ctn .match-odds-ctn .col .single-bet {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 30px;
    }
  
    .roulette-ctn .match-odds-ctn .col .single-bet .chip {
      display: inline-block;
    }
  
    .roulette-ctn .match-odds-ctn .col-Black:hover,
    .roulette-ctn .match-odds-ctn .col-Red:hover,
    .roulette-ctn .match-odds-ctn .even-num-color,
    .roulette-ctn .match-odds-ctn .inside-color,
    .roulette-ctn .match-odds-ctn .num-change,
    .roulette-ctn .match-odds-ctn .odd-num-color,
    .roulette-ctn .match-odds-ctn .range-color,
    .roulette-ctn .match-odds-ctn .red-blck-change,
    .roulette-ctn .match-odds-ctn .row-color-change {
      opacity: 1;
    }
  
    .roulette-ctn .columns-ctn {
      display: flex;
      flex-direction: row;
      height: 5%;
      width: 100%;
    }
  
    .roulette-ctn .columns-ctn .columns-layer {
      background: #4f4f4f;
      border-bottom: none;
      -o-border-image: linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0.4));
      border-image: linear-gradient(180deg, #d9d9d9, hsla(0, 0%, 85%, 0.4));
      border-image-slice: 1;
      border-right: 1px solid rgba(0, 0, 0, 0);
      width: 100%;
    }
  
    .roulette-ctn .columns-ctn .columns-layer .columns {
      font-family: Roboto Condensed, sans-serif;
      font-size: 16px;
      font-weight: 800;
      padding: 10px;
    }
  
    .roulette-ctn .columns-ctn .columns-layer:hover {
      opacity: 1;
    }
  
    .roulette-ctn .columns-ctn .columns-layer:first-child {
      border-bottom-left-radius: 30px;
      border-top-right-radius: 0;
    }
  
    .roulette-ctn .columns-ctn .columns-layer:nth-child(3) {
      border-bottom-right-radius: 30px;
      border-right: none;
    }
  
    .dozens-layer {
      align-items: center;
      width: 15%;
    }
  
    .dozens-layer .dozens-ctn {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 85%;
      width: 100%;
    }
  
    .dozens-layer .dozens-ctn .dozens {
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0);
      -o-border-image: linear-gradient(270deg, #d9d9d9, hsla(0, 0%, 85%, 0));
      border-image: linear-gradient(270deg, #d9d9d9, hsla(0, 0%, 85%, 0));
      border-image-slice: 1;
      border-right: none;
      display: flex;
      font-family: Roboto Condensed, sans-serif;
      font-size: 16px;
      font-weight: 800;
      height: 33.33%;
      justify-content: center;
      padding: 0;
      width: 100%;
    }
  
    .dozens-layer .dozens-ctn .dozens:hover {
      opacity: 1;
    }
  
    .last-row-ctn {
      align-items: center;
      margin-bottom: 0;
      width: 15%;
    }
  
    .last-row-ctn .last-row {
      flex-direction: column;
      height: 85%;
      width: 100%;
    }
  
    .last-row-ctn .last-row .even,
    .last-row-ctn .last-row .high,
    .last-row-ctn .last-row .low,
    .last-row-ctn .last-row .odd {
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0);
      -o-border-image: linear-gradient(270deg, #d9d9d9, hsla(0, 0%, 85%, 0));
      border-image: linear-gradient(270deg, #d9d9d9, hsla(0, 0%, 85%, 0));
      border-image-slice: 1;
      font-family: Roboto Condensed, sans-serif;
      font-size: 16px;
      font-weight: 800;
      height: 16.66%;
      justify-content: center;
      padding: 5px;
      text-transform: uppercase;
      width: 100%;
    }
  
    .last-row-ctn .last-row .even:hover,
    .last-row-ctn .last-row .high:hover,
    .last-row-ctn .last-row .low:hover,
    .last-row-ctn .last-row .odd:hover {
      opacity: 1;
    }
  
    .last-row-ctn .last-row .red-black {
      flex-direction: column;
      height: 33.33%;
      width: 100%;
    }
  
    .last-row-ctn .last-row .red-black .color-ctn {
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0);
      -o-border-image: linear-gradient(270deg, #d9d9d9, hsla(0, 0%, 85%, 0));
      border-image: linear-gradient(270deg, #d9d9d9, #d9d9d900);
      border-image-slice: 1;
      display: flex;
      flex-direction: column;
      font-family: Roboto Condensed, sans-serif;
      font-size: 16px;
      font-weight: 800;
      height: 50%;
      justify-content: center;
      text-transform: uppercase;
      width: 100%;
    }
  
    .last-row-ctn .last-row .red-black .color-ctn:hover {
      opacity: 1;
    }
    
  }
  /*      Wheel        */
  @media only screen and (min-width: 768px) and (max-width: 1399.99px) {
    .bhdLno{
      position: relative !important;
      width: 21vw !important;
      max-width: 700px !important;
      height: 21vw !important;
      max-height: 700px !important;
      object-fit: contain;
      flex-shrink: 0;
      z-index: 5;
      pointer-events: none;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 767.99px) {
      .bhdLno{
        width: 39vw !important;
        /* max-width: 445px !important; */
        height: 39vw !important;
        /* max-height: 537px !important; */
    }
   
  }
 
  .wheels{
    transform: translateX(0),translateY(90deg),translateZ(180deg);
  }
  .rotate{
    transform: rotateY(-180deg) !important;
    position: absolute;
    left: 15%;
    top: 15%;
  
  }
.rotate .rotate .iMLhtr{
  position: absolute;
    width: 82%;
    inset: 0px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    transform: rotate(0deg);
    height: 120%;
}



.gTLZXx{
  display: none !important;
}

 


  /*        History  */
  .table-h-div{
    background-color: #E4E4E4;
    width: 100%;
    /* height: 37px;a */
    color: #243A48;
    font: 13px;
    font-family: "mukta";
    font-weight: bold;
}
.My-Bets-tableR{
  position: absolute;
  z-index: 3;
  width: 55.4%;
}
.history-body{
  min-height: 10rem;
  max-height: 15rem;
}
@media (max-width: 992px) {
  .My-Bets-tableR{
    width: 100%;
  }
   
}
.imgback{
  position: relative;
}
.imgroll{
  position: absolute;
  top: -2%;
  left: 22.5%;
  width: 55%;
  transform: rotateX(48deg);
 
}
.imgcenter{
  position: absolute;
  left: 41%;
  top: 34%;
  transform: rotateX(0deg);
  width: 67px;
  z-index: 999;
}

.imageroted{
  animation: animName 60s linear infinite;
}
.bhdLno{
  animation: ball 60s linear infinite !important;
}

@keyframes animName {
  0%{
     transform: rotate(0deg);
    }
 100%{
     transform: rotate(360deg);
    }
 }
 @keyframes ball {
  0%{
     transform: rotate(360deg);
    }
 100%{
     transform: rotate(0deg);
    }
 }
 @media only screen and (min-width: 320px) and (max-width: 576.99px) {
  .imgcenter {
    position: absolute;
    left: 39% !important;
    top: 32% !important;
    transform: rotateX(0deg);
    width: 40px !important;
    z-index: 999;
  }
}
.mainhomeDev{
  margin-top: 90px;
}
