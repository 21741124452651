.depositesection .account-details .textright {
    font-size: 15px;
}

.depositesection .account-details .idcopy p {
    font-size: 15px;
}

.depositesection .account-details .idcopy i {
    font-size: 18px;
}

.depositesection .account-details {
    border-bottom: 1px solid #343131;
    padding-bottom: 4px;
}

.depositesection .text-animate {
    border: 2px solid #ecb024;
    text-decoration: none;
    text-align: center;
    width: 100%;
    padding: 8px 5px;
    border-radius: 8px;
    font-size: 16px;
    color: white;
    display: block;
}

.depositesection .paymentcard {
    background-color: #1b1b1b;
    border: 1px solid #343131;
    border-radius: 6px;
}

.depositesection .paymentcard .heading {
    font-size: 16px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
}

.depositesection .paymentcard .inputfilegroup label {
    font-size: 15px;
    color: white;
    text-align: left;
    margin-bottom: 3px;
}

.depositesection .paymentcard .inputfilegroup .inputimage {
    position: relative;
    height: 50px;
    background: #404040;
    border-radius: 10px;
    overflow: hidden;
}

.depositesection .paymentcard .inputfilegroup i {
    position: absolute;
    top: 12px;
    color: white;
    font-size: 26px;
    left: 10px;

}

.depositesection .paymentcard .inputfilegroup input {
    opacity: 0;
    width: 100%;
    position: absolute;
    height: 100%;
}

.depositesection .paymentcard .inputfilegroup input {}

.depositesection .paymentcard label {
    font-size: 15px;
    color: white;
    text-align: left;
    margin-bottom: 3px;
}

.depositesection .paymentcard .normalinput input {
    height: 50px;
    background: #404040;
    border-radius: 10px;
    overflow: hidden;
    font-size: 14px;
    color: whitesmoke;
}

.depositesection .paymentcard .normalinput input::placeholder {
    font-size: 14px;
    color: #878585;

}

.depositesection .paymentcard .mainrow {
    --bs-gutter-x: 1rem;
    margin-top: 10px;
}

.depositesection .paymentcard .amoutbtn {
    border: 1px solid #ababab;
    background-color: transparent;
    border-radius: 6px;
    font-size: 15px;
    font-weight: bold;
    color: #ababab;
    padding: 10px 15px;
    width: 100%;
    transition: all 0.3s ease-in-out;
}

.depositesection .paymentcard .amoutbtn:hover {
    background-color: #ecb024;
    border-color: #ecb024;
    color: black;
}

.depositesection .paymentcard .submit {
    overflow: hidden;
    position: relative;
    height: 40px;
    border-radius: 6px;
    border: 1px solid transparent;
    font-size: 1em;
    white-space: nowrap;
    color: #000000;
    background-image: linear-gradient(to right, #f2d71a 0%, #ecb024 100%);
    box-shadow: none;
    font-weight: 700;
    width: 100%;
    margin-top: 10px;
}

.depositesection .paymentcard .submit::after {
    background: #fff;
    content: "";
    height: 155px;
    left: -75px;
    opacity: .2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all .55s cubic-bezier(.19, 1, .22, 1);
    width: 50px;
    z-index: 999;
}

.depositesection .paymentcard .submit:hover:after {
    left: 120%;
    transition: all .55s cubic-bezier(.19, 1, .22, 1)
}

.depositesection .linkcard {
    background: #1b1b1b;
    border: 1px solid #343131;
    border-radius: 6px;
}

.depositesection .linkcard .payment-strip {
    text-align: left;
}

.depositesection .linkcard .payment-strip .title {
    color: #000;
    background: linear-gradient(90deg, #f2d71a -20%, transparent);
    display: block;
    padding: 10px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 16px;
}

.depositesection .linkcard .payment-strip .pointer {
    display: block;
    padding: 10px;
    border-radius: 3px;
    font-weight: 500;
    color: #fff;
}

.depositesection .notcard {
    background: #1b1b1b;
    border: 1px solid #343131;
    border-radius: 6px;
}

.depositesection .notcard .main-heading {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 8px;
}

.depositesection .notcard .notes {
    list-style: none;
    padding: 0;
    position: relative;
    border-left: 1px dashed #404040;
}

.depositesection .notcard .notes li {
    padding-left: 15px;
    position: relative;
    font-size: 13px;
    color: white;
    font-weight: 100;
    text-align: left;
    margin-bottom: 10px !important;
}

.depositesection .notcard .notes li::before {
    content: "\f111";
    font-family: FontAwesome;
    margin-right: 10px;
    color: #ffdf02;
    font-size: 9px;
    position: absolute;
    left: -5px;

}

.depositesection .notcard p {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

@media (min-width: 320px) and (max-width: 576px) {
    .seperator {
        zoom: 0.8;
    }

    .depositesection .text-animate {
        zoom: 0.8;
    }

    .paymentcard,
    .linkcard,
    .notcard {
        zoom: 0.8;
    }
}

/* #picture__input {
    display: none;
}

.picture {
    width: 400px;
    aspect-ratio: 16/9;
    background: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #aaa;
    border: 2px dashed currentcolor;
    cursor: pointer;
    font-family: sans-serif;
    transition: color 300ms ease-in-out, 
    background 300ms ease-in-out;
    outline: none;
    overflow: hidden;
}


.picture__img {
    max-width: 100%;
} */