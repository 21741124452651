.onedaydragionTigersection {
    background-color: white;
}

.onedaydragionTigersection .casino-nation-detail {
    width: 60%;
}

.onedaydragionTigersection .casino-odds-box {
    width: 20%;
}

.onedaydragionTigersection .dtpair {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: #f2f2f2;
    border-bottom: 1px solid #c7c8ca;
}

.casino-table {
    background-color: #f7f7f7;
    color: #333;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 5px;
}

.casino-table-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.casino-table-left-box,
.casino-table-center-box,
.casino-table-right-box {
    width: 49%;
    border-left: 1px solid #c7c8ca;
    border-right: 1px solid #c7c8ca;
    border-top: 1px solid #c7c8ca;
    background-color: #f2f2f2;
}

.casino-table-header,
.casino-table-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #c7c8ca;
}

.onedaydragionTigersection.casino-nation-detail {
    width: 60%;
}

.casino-table-header .casino-nation-detail {
    font-weight: bold;
    min-height: unset;
}

.casino-nation-detail {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 5px;
    min-height: 46px;
}

.onedaydragionTigersection.casino-odds-box {
    width: 20%;
}

.casino-table-header .casino-odds-box {
    cursor: unset;
    padding: 2px;
    min-height: unset;
    height: auto !important;
}

.casino-odds-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    font-weight: bold;
    border-left: 1px solid #c7c8ca;
    cursor: pointer;
    min-height: 56px;
    /* min-height: 46px; */
}

.casino-table-header,
.casino-table-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #c7c8ca;
}

.casino-nation-detail {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 5px;
    min-height: 46px;
}

.onedaydragionTigersection.casino-nation-detail {
    width: 60%;
}

.casino-nation-name {
    font-size: 16px;
    font-weight: bold;
    color: black;
}

.casino-odds {
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
}

.suspended-box {
    position: relative;
    pointer-events: none;
    cursor: none;
}

.back {
    background-color: #2196F3 !important;
}

.onedaydragionTigersection.casino-odds-box.suspended-box::before {
    top: -12px;
}

.suspended-box::before {
    background-image: url(../../../src/Media/lock.svg);
    background-size: 17px 17px;
    filter: invert(1);
    -webkit-filter: invert(1);
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    pointer-events: none;
}

.suspended-box::after {
    content: "";
    background-color: #373636d6;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    cursor: not-allowed;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}


.lay {
    background-color: #d67f23 !important;
}

.onedaydragionTigersection.dtpair {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: #f2f2f2;
    border-bottom: 1px solid #c7c8ca;
}

.casino-table-left-box,
.casino-table-center-box,
.casino-table-right-box {
    width: 49%;
    border-left: 1px solid #c7c8ca;
    border-right: 1px solid #c7c8ca;
    border-top: 1px solid #c7c8ca;
    background-color: #f2f2f2;
}

.casino-odds {
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
}

.onedaydragionTigersection .dtpair .casino-odds-box {
    width: 100%;
    margin: 5px 0;
    padding: 10px;
}

.casino-odds-box-theme {
    background-image: linear-gradient(to right, #0088cc, #2c3e50);
    color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    border: 0;
}

.casino-odds-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    font-weight: bold;
    border-left: 1px solid #c7c8ca;
    cursor: pointer;
    min-height: 46px;
}

.suspended-box {
    position: relative;
    pointer-events: none;
    cursor: none;
}

.onedaydragionTigersection.dtredblack .casino-table-header .casino-odds-box {
    flex-direction: row;
}

.onedaydragionTigersection.casino-odds-box {
    width: 20%;
}

.casino-table-header .casino-odds-box {
    cursor: unset;
    padding: 2px;
    min-height: unset;
    height: auto !important;
}

.casino-odds-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    font-weight: bold;
    border-left: 1px solid #c7c8ca;
    cursor: pointer;
    min-height: 46px;
}

.card-red,
.card-black {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .onedaydragionTigersection.dtredblack .casino-table-header .casino-odds-box {
        font-size: 13px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .onedaydragionTigersection.dtredblack .casino-table-header .casino-odds-box {
        font-size: 13px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .onedaydragionTigersection.dtredblack .casino-table-header .casino-odds-box {
        font-size: 13px;
    }
}

.casino-odds-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    font-weight: bold;
    border-left: 1px solid #c7c8ca;
    cursor: pointer;
    min-height: 46px;
}

.casino-table-header .casino-odds-box {
    cursor: unset;
    padding: 2px;
    min-height: unset;
    height: auto !important;
}

.onedaydragionTigersection.casino-odds-box {
    width: 20%;
}

.onedaydragionTigersection.dtredblack .casino-table-header .casino-odds-box {
    flex-direction: row;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .onedaydragionTigersection.dtredblack .casino-table-header .casino-odds-box {
        font-size: 13px;
    }
}

.card-icon {
    font-family: Card Characters !important;
    display: inline-block;
}

.casino-table-full-box {
    width: 100%;
    border-left: 1px solid #c7c8ca;
    border-right: 1px solid #c7c8ca;
    border-top: 1px solid #c7c8ca;
    background-color: #f2f2f2;
}

.onedaydragionTigersection .dt1day-other-odds .casino-nation-detail,
.onedaydragionTigersection .dt1day-other-odds .casino-odds-box {
    width: 20%;

}

.casino-table-header,
.casino-table-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #c7c8ca;
}

.onedaydragionTigersection .dt1day-other-odds .casino-nation-detail,
.onedaydragionTigersection .dt1day-other-odds .casino-odds-box {
    width: 20%;
}
.minred{
    display: flex;
    flex-direction: row;
    font-size: 11px;
}

@media screen and (min-width: 320px) and (max-width: 576px) {
    .casino-table-left-box{
        width: 100%;
    }
    .casino-table-right-box{
        width: 100%;
    }
    .casino-nation-name{
        font-size: 13px;
    }
    .casino-odds-box{
        font-size: 13px;

    }
}


 .casino-result-desc {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding: 6px;
    box-shadow: 0 0 4px -1px;
    margin-top: 10px;
}
 .casino-result-desc-item {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}
 .casino-result-desc-item div:first-child {
    margin-right: 2%;
    opacity: 0.6;
}
 .casino-result-desc-item div:last-child {
    word-break: break-all;
}
.mainhomeDev{
    margin-top: 90px;
}
