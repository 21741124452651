@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700&family=Tourney:ital,wght@0,100..900;1,100..900&display=swap");
.thirtytwoB-game-div {
  height: 89vh;
  /* width: 50%; */
  overflow-y: scroll;
}

.thirtytwoB-game-div::-webkit-scrollbar {
  display: none;
}
.teenpatti-game-h {
  background-color: #2c3d51;
  width: 100%;
  height: 39px;
}
.teenpatti-game-h p {
  color: white;
  font-family: "mukta";
  font-size: 17px !important;
  font-weight: 500;
}
.player-a-div {
  color: white;
  font-size: 16px;
  font-weight: 600;
  font-family: "mukta";
}
.teenpatti-game-card {
  height: 270px;
  width: 100%;
  /* border-radius: 6px; */
  background-color: rgb(206, 206, 221);
}
.gamecard-div {
  height: 38px;
  width: 32px;
  background-color: #2c3d51;
  border: 1px solid #ff8a0c;
}

.table-h-div {
  background-color: #e4e4e4;
  width: 100%;
  height: 37px;
  color: #243a48;
  font: 13px;
  font-family: "mukta";
  font-weight: bold;
  border-radius: 5px;
}
.digit-card {
  height: 300px;
}
.zero-div {
  height: 32px;
}
.zero-div:hover {
  background-color: #373e42;
  color: white;
}
.digits-card-h {
  height: 35px;
  background-color: #e4e4e4;
  width: 100%;
  height: 35px;
  color: #243a48;
  font-size: 13px;
  font-family: "mukta";
  border-radius: 5px;
  /* font-weight: bold; */
}
.digit-zero {
  color: rgb(238, 5, 5);
  font-size: 16px;
}
.digits-button {
  background-color: #3d444b;
  height: 100px;
  width: 19.5%;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 32px;
  font-weight: 600;
  /* font-family: */
  /* font-family: "mukta"; */
  font-family: "tourney";
  color: white;
}

.card-table-inner-row {
  background-color: white;
  width: 100%;
  height: 45px;
  color: #243a48;
  font-size: 16px;
  font-family: "mukta";
  font-weight: 500;
  border-top: 1px solid #b0b5b8;
}
.cardB-table-inner-row {
  background-color: white;
  width: 100%;
  height: 57px;
  color: #243a48;
  font-size: 16px;
  font-family: "mukta";
  font-weight: 500;
  border-top: 1px solid #b0b5b8;
}
.back-btn {
  height: 44px;
  background-color: #2196f3;
  width: 90px;
  border: none;
  font-size: 13px;
}
.backB-btn {
  height: 56px;
  background-color: #2196f3;
  width: 90px;
  border: none;
  font-size: 13px;
}
.backS-btn {
  height: 44px;
  background-color: #2196f3;
  /* width:90px; */
  width: 100%;
  border: none;
  font-size: 13px;
}
.layB-btn {
  height: 56px;
  background-color: #d67f23;
  width: 90px;
  border: none;
  font-size: 13px;
}
.lay-btn {
  height: 44px;
  background-color: #d67f23;
  width: 90px;
  border: none;
  font-size: 13px;
}
.zero {
  color: #e32e27;
  font-size: 11px;
}
.zero-1 {
  color: #e32e27;
  font-size: 17px;
}

.success-1 {
  color: #3eb33c;
  font-size: 11px;
}

.success-o {
  color: #3eb33c;
  font-size: 17px;
}
.rst-card {
  height: 60px !important;
  background-color: white !important;
  width: 100%;
}
.rst-btn {
  background-color: black;
  color: #25c321;
  font-family: "mukta";
  border: 1px solid black;
  height: 35px;
  width: 35px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.red-dot {
  width: 20px;
  height: 20px;
  background-color: #e32e27;
  border-radius: 10px;
}
.violet-dot {
  width: 20px;
  height: 20px;
  background-color: rgb(141 77 181);
  border-radius: 10px;
}
.green-dot {
  width: 20px;
  height: 20px;
  background-color: #25c321;
  border-radius: 10px;
}
.plus-icon {
  height: 20px;
  width: 25px;
}
.fail {
  color: rgb(235, 44, 44) !important;
}
.success {
  color: #25c321;
}
.bet-menu-card {
  width: 100%;
  height: 370px;
  color: #243a48;
  font-size: 17px;
  font-family: "mukta";
  font-weight: 500;
}
.bet-menu-card-h {
  color: #0ecf9f;
  font-size: 18px;
  font-family: "mukta";
}
.orange-circle {
  /* height: 30px; */
  /* width: 40px; */
  border-radius: 10px;
  color: white;
  background-color: #d67f23;
}
/* <<<<<<<<<<<<<<------------------------------ModalData-------------->>>>>>> */
.color-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: none;
}

.color-modal-container {
  position: absolute;
  width: 520px;
  height: 370px;
  /* background: linear-gradient(105deg, #7D2DE6 0%, #7E2EE7 40%, #7C2CE5 100%); */
  /* background-color: #01a839; */
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* border-radius: 8px; */
  z-index: 999;
}
.model-h-dive {
  background-color: #cfd5d9;
  font-size: 17px;
  font-weight: bold;
  height: 43px;
}
.num-box {
  height: 40px;
  width: 80px;
  border: none;
  color: #9e9a9a;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: white;
  /* background-color: rgb(165, 152, 152); */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}
.num-box.is-activated {
  background-color: #9e9a9a !important;
  color: black;
}
.minus-div {
  width: 50px;
  height: 45px;
  border: none;
  color: #9e9a9a;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: white;
  font-size: 20px;
}
.plus-div {
  width: 50px;
  height: 45px;
  border: none;
  color: #9e9a9a;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: white;
  font-size: 20px;
}
.counter-data-div {
  width: 200px;
  height: 45px;
  color: black;
  font-size: 20px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.data-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: none;
}
.data-modal-container {
  position: absolute;
  width: 470px;
  height: 335px;
  /* background: linear-gradient(105deg, #7D2DE6 0%, #7E2EE7 40%, #7C2CE5 100%); */
  background-color: black;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* border-radius: 8px; */
  z-index: 999;
  border: none;
}
.container-body {
  color: white;
  font-size: 17px;
  font-family: "mukta";
  font-weight: 600;
}
.modal-w-btn {
  background-color: white;
  height: 35px;
  width: 150px;
  border: none;
  border-radius: 5px;
  color: black;
  font-size: 15px;
}
.modal-g-btn {
  background-color: #25c321;
  height: 30px;
  width: 90px;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 15px;
}
.orb {
  background-color: #ff8a0e;
  color: white;
  height: 27px;
  width: 70px;
  border: none;
  border-radius: 4px;
  font-size: 15px;
}
.modal-footer {
  border-top: 2px solid white;
  width: 95%;
}
.mcb {
  height: 40px;
  width: 75px;
  background-color: rgb(204, 41, 41);
  border: none;
  color: white;
  border-radius: 5px;
  font-family: "mukta";
}
.mbb {
  height: 40px;
  width: 85px;
  background-color: #25c321;
  border: none;
  color: white;
  border-radius: 5px;
  font-family: "mukta";
}
.Results-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: none;
}
.head-border {
  background-color: #faad07;
  color: rgb(17, 16, 16);
  height: 39px;
  font-family: "mukta";
  font-size: 18px;
  font-weight: 600;
}

.results-modal-container {
  position: absolute;
  width: 470px;
  height: 350px;
  /* background: linear-gradient(105deg, #7D2DE6 0%, #7E2EE7 40%, #7C2CE5 100%); */
  /* background-color: #01a839; */
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* border-radius: 8px; */
  z-index: 999;
  border: 1px solid white;
}
.results-modal-h {
  font-size: 16px;
  font-family: "mukta";
}
.cross-icon-result {
  font-size: 22px;
}
.tropy-icon-div {
  width: 80%;
}
.trophy-icon {
  color: white;
  /* margin-right: 50px;; */
}
@media screen and (max-width: 500px) {
  .teenpatti-game-h p {
    color: white;
    font-family: "mukta";
    font-size: 13px !important;
    font-weight: 500;
  }
  .results-modal-container {
    position: absolute;
    width: 400px;
    height: 350px;
    /* background: linear-gradient(105deg, #7D2DE6 0%, #7E2EE7 40%, #7C2CE5 100%); */
    /* background-color: #01a839; */
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* border-radius: 8px; */
    z-index: 999;
    border: 1px solid white;
  }
  .data-modal-container {
    position: absolute;
    width: 400px;
    height: 335px;
    /* background: linear-gradient(105deg, #7D2DE6 0%, #7E2EE7 40%, #7C2CE5 100%); */
    background-color: black;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* border-radius: 8px; */
    z-index: 999;
    border: none;
  }
}

/* 32 Card B Satrt */
.casino-table {
  background-color: var(--bg-table);
  color: var(--text-table);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5px;
}

.casino-table-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.casino-table-left-box,
.casino-table-center-box,
.casino-table-right-box {
  width: 49%;
  border-left: 1px solid var(--table-border);
  border-right: 1px solid var(--table-border);
  border-top: 1px solid var(--table-border);
  background-color: var(--bg-table-row);
}
.casino-table-header,
.casino-table-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid var(--table-border);
}
.cards32b .casino-nation-detail {
  width: 60%;
}
.casino-table-header .casino-nation-detail {
  font-weight: bold;
  min-height: unset;
}
.casino-nation-detail {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 5px;
  min-height: 46px;
}
.casino-table-header .casino-odds-box {
  cursor: unset;
  padding: 2px;
  min-height: unset;
  height: auto !important;
}
casino-odds-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  font-weight: bold;
  border-left: 1px solid var(--table-border);
  cursor: pointer;
  min-height: 46px;
}
.back {
  background-color: #72bbef !important;
}
.lay {
  background-color: #faa9ba !important;
}
.casino-nation-name {
  font-size: 14px;
  font-weight: bold;
}
.cards32b .casino-odds-box {
  width: 20%;
}
.casino-odds-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  font-weight: bold;
  border-left: 1px solid var(--table-border);
  cursor: pointer;
  min-height: 46px;
}
.suspended-box {
  position: relative;
  pointer-events: none;
  cursor: none;
}
.casino-odds {
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
}
.suspended-box::before {
  /* background-image: url(../img/icons/lock.svg); */
  background-size: 17px 17px;
  filter: invert(1);
  -webkit-filter: invert(1);
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  pointer-events: none;
}
.suspended-box::after {
  content: "";
  background-color: #373636d6;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.cards32b .cards32total .casino-odds-box {
  height: 69px;
  width: 40%;
}
.card32numbers-container {
  display: flex;
  flex-wrap: wrap;
}
.cards32b .card32numbers-container .casino-odds-box {
  border-bottom: 1px solid var(--table-border);
}
.cards32b .casino-odds-box {
  width: 20%;
}
.card32numbers .casino-odds {
  font-family: Casino;
  font-size: 48px;
  height: 40px;
  line-height: 40px;
}
.mainhomeDev{
  margin-top: 90px;
}

