/* andar bahar model css start */
.newmodel {
  width: 60% !important;
}
.mt-10 {
  margin-top: 3.2rem;
}
.wide {
  overflow-y: scroll;
  width: 530px;
}
.back-btn {
  height: 44px;
  background-color: #2196f3;
  width: 100px;
  padding: 0 15px;
  border: none;
  font-size: 13px;
}

.teenpatti1day-other-odds .casino-odds-box {
  width: 13%;
  color: black;
}
.teenpatti1day-other-odds .casino-nation-detail {
  width: 22%;
}
.card-black1 {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.suspended__12 {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.55);
  padding: 0px;
  left: 15px;
  width: 75%;
  text-align: center;
  height: 44px;
  z-index: 2;
}
.suspended__12 i {
  padding-top: 14px;
  color: white !important;
}
.suspended__13 {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.55);
  padding: 0px;
  left: 45px;
  width: 50%;
  text-align: center;
  height: 44px;
  z-index: 2;
}
.suspended__13 i {
  padding-top: 14px;
  color: white !important;
}

@media only screen and (max-width: 600px) {
  .newmodel {
    width: 95% !important;
  }
  .mt-10 {
    margin-top: 3.8rem;
  }
  .wide {
    overflow-y: scroll;
    width: 90%;
  }
  .mediamobile {
    display: flex;
    justify-content: space-between;
  }
  .topmarge {
    padding-top: 2rem;
  }
}
.topmarge {
  padding-top: 2.5rem;
}

/* andar bahar end css */

.teenpatti-game-div {
  height: 89vh;
  /* width: 50%; */
  overflow-y: scroll;
}
.d-t-box {
  background-color: #202020;
  padding: 10px;
}
.m-t-10 {
  margin-top: 10px !important;
  text-align: center;
}
.suspended {
  position: relative;
}
/* .textss{
    color: #000;
} */
/* .suspended:after {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffff;
    text-transform: uppercase;
    font-family: "Font Awesome 5 Free";
    content: "\f023";
    font-weight: 900;
    font-size: 18px;
    top: 0;
} */
.suspended {
  position: relative;
}
.card-dt .card-image img {
  width: 45px;
  cursor: pointer;
}
.btn-theme {
  color: #ffff !important;
  background-image: linear-gradient(to right, #0088cc, #2c3e50) !important;
  width: 85% !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
  border: 0 !important;
  padding: 8px 10px !important;
  cursor: pointer !important;
  font-size: 16px !important;
  position: relative;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.teenpatti-game-div::-webkit-scrollbar {
  display: none;
}
.teenpatti-game-h {
  background-color: #2c3d51;
  width: 100%;
  height: 39px;
  color: white;
  font-family: "mukta";
  font-size: 18px;
  font-weight: 500;
}
.teenpatti-h-p {
  color: white;
  font-family: "mukta";
  font-size: 18px !important;
  font-weight: 500;
}
.player-a-div {
  color: #ff8a0c;
  font-size: 15px;
  font-weight: 600;
  font-family: "mukta";
}
.teenpatti-game-card {
  height: 270px;
  width: 100%;
  /* border-radius: 6px; */
  background-color: rgb(206, 206, 221);
}
.gamecard-div {
  height: 45px;
  width: 35px;
  background-color: #2c3d51;
  border: 1px solid #ff8a0c;
}

.table-h-div {
  background-color: #e4e4e4;
  width: 100%;
  height: 37px;
  color: #243a48;
  font: 13px;
  font-family: "mukta";
  font-weight: bold;
}
.card-table-inner-row {
  background-color: white;
  width: 100%;
  height: 45px;
  color: #243a48;
  font-size: 16px;
  font-family: "mukta";
  font-weight: 500;
  border-top: 1px solid #b0b5b8;
}
.back-btn {
  height: 44px;
  background-color: #2196f3;
  width: 90px;
  border: none;
}
.lay-btn {
  height: 44px;
  background-color: #d67f23;
  width: 90px;
  border: none;
}
.success-o {
  color: #3eb33c;
  font-size: 17px;
}
.rst-card {
  height: 60px !important;
  background-color: white !important;
  width: 100%;
}
.rst-btn {
  background-color: black;
  color: #25c321;
  font-family: "mukta";
  border: 1px solid black;
  height: 35px;
  width: 35px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.red-dot {
  width: 20px;
  height: 20px;
  background-color: #e32e27;
  border-radius: 10px;
}
.violet-dot {
  width: 20px;
  height: 20px;
  background-color: rgb(141 77 181);
  border-radius: 10px;
}
.green-dot {
  width: 20px;
  height: 20px;
  background-color: #25c321;
  border-radius: 10px;
}
.plus-icon {
  height: 20px;
  width: 25px;
}
.fail {
  color: rgb(235, 44, 44) !important;
}
.success {
  color: #25c321;
}
.bet-menu-card {
  width: 100%;
  height: 370px;
  color: #243a48;
  font-size: 17px;
  font-family: "mukta";
  font-weight: 500;
}
.bet-menu-card-h {
  color: #0ecf9f;
  font-size: 18px;
  font-family: "mukta";
}
/* <<<<<<<<<<<<<<------------------------------ModalData-------------->>>>>>> */
.color-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: none;
}

.color-modal-container {
  position: absolute;
  width: 520px;
  height: 370px;
  /* background: linear-gradient(105deg, #7D2DE6 0%, #7E2EE7 40%, #7C2CE5 100%); */
  /* background-color: #01a839; */
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* border-radius: 8px; */
  z-index: 999;
}
.model-h-dive {
  background-color: #cfd5d9;
  font-size: 17px;
  font-weight: bold;
  height: 43px;
}
.num-box {
  height: 40px;
  width: 80px;
  border: none;
  color: #9e9a9a;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: white;
  /* background-color: rgb(165, 152, 152); */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}
.num-box.is-activated {
  background-color: #9e9a9a !important;
  color: black;
}
.minus-div {
  width: 50px;
  height: 45px;
  border: none;
  color: #9e9a9a;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: white;
  font-size: 20px;
}
.plus-div {
  width: 50px;
  height: 45px;
  border: none;
  color: #9e9a9a;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: white;
  font-size: 20px;
}
.counter-data-div {
  width: 200px;
  height: 45px;
  color: black;
  font-size: 20px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.Results-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: none;
}
.data-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: none;
}
.data-modal-container {
  position: absolute;
  width: 470px;
  height: 250px;
  /* background: linear-gradient(105deg, #7D2DE6 0%, #7E2EE7 40%, #7C2CE5 100%); */
  background-color: black;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* border-radius: 8px; */
  z-index: 999;
  border: none;
}
.container-body {
  color: white;
  font-size: 17px;
  font-family: "mukta";
  font-weight: 600;
}
.modal-w-btn {
  background-color: white;
  height: 35px;
  width: 150px;
  border: none;
  border-radius: 5px;
  color: black;
  font-size: 15px;
}
.modal-g-btn {
  background-color: #25c321;
  height: 30px;
  width: 90px;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 15px;
}
.orb {
  background-color: #ff8a0e;
  color: white;
  height: 27px;
  width: 70px;
  border: none;
  border-radius: 4px;
  font-size: 15px;
}
.modal-footer {
  border-top: 2px solid white;
  width: 95%;
}
.mcb {
  height: 40px;
  width: 75px;
  background-color: rgb(204, 41, 41);
  border: none;
  color: white;
  border-radius: 5px;
  font-family: "mukta";
}
.mbb {
  height: 40px;
  width: 85px;
  background-color: #25c321;
  border: none;
  color: white;
  border-radius: 5px;
  font-family: "mukta";
}
.teen-results-modal-container {
  position: absolute;
  width: 400px;
  height: auto;
  /* background: linear-gradient(105deg, #7D2DE6 0%, #7E2EE7 40%, #7C2CE5 100%); */
  /* background-color: #01a839; */
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* border-radius: 8px; */
  z-index: 999;
  border: 1px solid white;
}
@media screen and (max-width: 500px) {
  .teenpatti-h-p {
    color: white;
    font-family: "mukta";
    font-size: 13px !important;
    font-weight: 500;
  }
  .teen-results-modal-container {
    position: absolute;
    width: 400px;
    height: 230px;
    /* background: linear-gradient(105deg, #7D2DE6 0%, #7E2EE7 40%, #7C2CE5 100%); */
    /* background-color: #01a839; */
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* border-radius: 8px; */
    z-index: 999;
    border: 1px solid white;
  }
  .data-modal-container {
    position: absolute;
    width: 400px;
    height: 250px;
    /* background: linear-gradient(105deg, #7D2DE6 0%, #7E2EE7 40%, #7C2CE5 100%); */
    background-color: black;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* border-radius: 8px; */
    z-index: 999;
    border: none;
  }
  .teenpatti-game-h {
    background-color: #2c3d51;
    width: 100%;
    height: 50px !important;
  }
}
.mainhomeDev{
  margin-top: 90px;
}
