@media only screen and (max-width: 600px) {
    .registration-card {

        background-color: rgb(17, 16, 16);
        position: absolute;
        z-index: 99999;
        top: -4% !important;
        padding: 0px 20px !important;
        height: 500px;
        overflow: auto;

    }
}

.registration-card {
    /* height: 370px; */
    /* width: 400px; */
    background-color: rgb(17, 16, 16);
    position: absolute;
    z-index: 99999;
    top: 8%;
    padding: 20px;

}

.form-label {
    color: white;
}

.registration-card form {
    color: white;
}

.registration-input {
    outline: none !important;
    border: none !important;
    background-color: rgb(29, 25, 25);
    height: 35px;
    width: 95%;
    border-radius: 5px;
    font-size: 14px;
    color: white !important;

}

.css-i4bv87-MuiSvgIcon-root {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    color: white !important;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
}

.registration-btn {
    width: 100%;
    background-color: #009B2f;
    color: white;
    font-family: "mukta";
}

.registration-btn:hover {
    background-color: #fa5004;
}

.bth {
    color: white;
    font-size: 15px;
}

.bth span {
    color: #fa5004;

}
@media (min-width: 320px) and (max-width: 576px) {
    .texttrems{
        font-size: 14px;
    }
    .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
        font-size: 14px;
    }
  }
  .fs-14 span{
    font-size: 14px;
  }
